import React, { useState } from 'react';
import EnrolmentFormHeader from './header';
import EnrolmentFormBreadcrumb from './breadcrumb';
import EnrolmentFormPersonalDataId from './personaldataid';
import EnrolmentFormStudies from './studies';
import EnrolmentFormAuthorizations from './authorizations';
import axios from 'axios';
import { v4 as uuidv4 } from "uuid"
import config from '../../config';

const uuid = uuidv4()

export default function EnrolmentForm() {
  
  
  let enguany = new Date().getFullYear()
  let anyVinent = enguany + 1
  anyVinent = anyVinent.toString().slice(-2)

  const [dadesPersonals, setDadesPersonals] = useState({'validat': false})
  const [dadesMediques, setDadesMediques] = useState({'validat': false})
  const [dadesLaborals, setDadesLaborals] = useState({'validat': false})
  const [dadesEstudis, setDadesEstudis] = useState({'validat': false})
  const [dadesAutoritzacions, setDadesAutoritzacions] = useState({'validat': false})

  const [inscripcio, setInscripcio] = useState();
  const [sending, setSending] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    const dadesPost = {
      cid: config.cid,
      uuid: uuid,
      dadesPersonals,
      dadesMediques,
      dadesLaborals,
      dadesEstudis,
      dadesAutoritzacions,
    }

    if(!sending)
    {
      setSending(true)

      console.log('SENDING!',uuid, dadesPost)

      const headers = { 'Content-Type': 'multipart/form-data' }

      axios.post(config.enrollmentPostURL, dadesPost, headers)
        .then(response => setInscripcio(response.data))
        .catch((err) => { 
          console.log(err)
          setInscripcio({response: "ko", error: err})
          setSending(false) // si hi ha error donem l'oportunitat de tornar a enviar ??
        });
    }

    return false;
  }
  
  const handleChangeDadesPersonals = (d) => {
    //console.log('handleChangeDadesPersonals1',e)
    //console.log('handleChangeDadesPersonals2',dadesPersonals)
    setDadesPersonals(d)
    //console.log('handleChangeDadesPersonals3',dadesPersonals)
  }
  
  const handleChangeDadesMediques= (d) => {
    setDadesMediques(d)
  }
  
  const handleChangeDadesLaborals = (d) => {
    setDadesLaborals(d)
  }
  
  const handleChangeDadesEstudis = (d) => {
    setDadesEstudis(d)
    //console.log('d1',dadesEstudis)
    //console.log('d2',d)
  }
  
  const handleChangeDadesAutoritzacions = (d) => {
    setDadesAutoritzacions(d)
  }


  const BotoInscriure = (props) => {

    return (<div className="container">
      <div className="row">
        <div className="col col-12 submitButtonDiv" align="center">
          { props.showText && (<>Fent click al següent botó formalitzareu la matrícula:<br /></>) }
          <button className="submitButton" disabled={props.sending}>{props.sending ? "ENVIANT..." : config.textBotoMatricular }</button>
        </div>
      </div>
    </div>)
  }
  
  
  return (
    <form action={config.enrollmentPostURL} method="POST" onSubmit={submitForm}>

      <div className="t-Body">

        <EnrolmentFormHeader />
            <EnrolmentFormBreadcrumb dadesPersonals={dadesPersonals}
                                     dadesMediques={dadesMediques}
                                     dadesLaborals={dadesLaborals}
                                     dadesEstudis={dadesEstudis}
                                     dadesAutoritzacions={dadesAutoritzacions}
               />

        <div className="row">
          <div className="col col-12 ">
            <div
              className="t-BreadcrumbRegion t-BreadcrumbRegion--showBreadcrumb t-BreadcrumbRegion--useBreadcrumbTitle">
              <div className="t-BreadcrumbRegion-body">

                <div className="t-BreadcrumbRegion-breadcrumb">
                  <ul className="t-Breadcrumb ">
                    <li className="t-Breadcrumb-item is-active">
                      <h1 className="t-Breadcrumb-label">Matrícula {enguany}/{anyVinent}</h1>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="t-Body-content" id="t_Body_content">
          <div className="t-Body-main" style={{ marginTop: "0px" }}>
            {/*<div className="t-Body-title" id="t_Body_title" style={{ top: "40px" }}></div>*/}
            <div className="t-Region">

              {/*  
              ::{ dadesPersonals.map( (k,v) => (<div> {v}</div>) )}:: 
              */}

              <div className="t-Body-content">
                <div className="t-Region-bodyWrap">
                  <div className="t-Region-body">

                    {inscripcio === undefined && (
                      <React.Fragment>
                        <EnrolmentFormPersonalDataId dadesPersonals={dadesPersonals} handleDadesPersonals={handleChangeDadesPersonals} 
                                                     dadesMediques={dadesMediques} handleDadesMediques={handleChangeDadesMediques}
                                                     dadesLaborals={dadesLaborals} handleDadesLaborals={handleChangeDadesLaborals}
                                                   />

                        {dadesPersonals.validat && dadesMediques.validat && dadesLaborals.validat && (
                          <React.Fragment>
                            <EnrolmentFormStudies dadesPersonals={dadesPersonals} 
                                                  dadesEstudis={dadesEstudis} handleDadesEstudis={handleChangeDadesEstudis} 
                                                  />

                        {dadesEstudis.validat && (
                            <EnrolmentFormAuthorizations dadesPersonals={dadesPersonals} 
                                                         dadesEstudis={dadesEstudis}
                                                         enguany={enguany} anyVinent={anyVinent} 
                                                         dadesAutoritzacions={dadesAutoritzacions} handleDadesAutoritzacions={handleChangeDadesAutoritzacions} 
                                                         />)}


                        {dadesPersonals.validat &&
                        dadesMediques.validat && 
                        dadesLaborals.validat && 
                        dadesEstudis.validat && 
                        dadesAutoritzacions.validat && (

                          <BotoInscriure sending={sending} showText={true}/>
                            )}

                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )
                    }

                    { inscripcio?.response === "ok" && (
                      <div className="container inscripcioBlock">
                        <div className="inscripcioContingut col col-5">
                          <h2>
                            Inscripció realitzada correctament
                          </h2>
                          { config.pagamentTPV && (
                            <h3 className="inscripcioTPV">
                              Cal accedir a <br/><a href={ config.urlTPV+"?uuid=" + uuid + "&iddocument=" + dadesPersonals?.iddocument } target="_blank">{config.urlTPV}</a>  <br/> per realitzar pagament i formalitzar la inscripció
                            </h3>
                           )}

                           { config.textInscripcioText &&  (
                          <div className="inscripcioText">
                            {config.textInscripcioText()}
                          </div>)}

                          <div className="inscripcioText">
                            El vostre número d'inscripció és el:
                          </div>
                          <div className="inscripcioNumero">{inscripcio?.id}</div>
                          <div className="inscripcioText">
                            Proporcioneu aquest número per a qualsevol tràmit en referència a aquesta inscripció.
                          </div>
                          <div className="inscripcioText">
                            Per a qualsevol dubte, contacteu a:<br/>
                              <ul>
                                <li>Telèfon <a href={'tel:+34' + config.phone.replace(/ /g, '')}>{config.phone}</a></li>
                                <li>A/e: <a href={config.email}>{config.email}</a></li>
                              </ul>
                          </div>
                        <p>
                          
                        </p>
                      </div>
                      </div>
                    )
                    }

                    {inscripcio?.response === "ko" && (
                      <div className="container inscripcioBlock">
                        <div className="inscripcioContingut col col-5">
                          <h2>
                            Error en la inscripció
                          </h2>
                          <div className="inscripcioText">
                            <small> {inscripcio.response.error} </small><br/>
                            Torneu-ho a provar passats uns minuts<br/>
                            <BotoInscriure sending={sending} showText={false} />
                          </div>
                          <div className="inscripcioText">
                            O contacteu amb:<br/>
                              <ul>
                                <li>Telèfon <a href={'tel:+34' + config.phone.replace(/ /g, '')}>{config.phone}</a></li>
                                <li>A/e: <a href={config.email}>{config.email}</a></li>
                              </ul>
                          </div>
                        <p>
                          
                        </p>
                      </div>
                      </div>
                    )
                    }

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </form>
  );
}