//import React, { useEffect, useState } from 'react';
import config from '../../../config';
import { EnrolmentFormAuthorizations as  EnrolmentFormAuthorizationsCastellarnau} from './authorizations.castellarnau';
import { EnrolmentFormAuthorizations as EnrolmentFormAuthorizationsJoanOro } from './authorizations.joanoro';
import { EnrolmentFormAuthorizations as EnrolmentFormAuthorizationsCavallBernat } from './authorizations.cavallbernat';

export default function EnrolmentFormAuthorizations(props)
{
  // console.log("CID 7",config.cid)

  switch(config.cid)
  {
     // Joan Oro
   case 'a8037152': 
   return EnrolmentFormAuthorizationsJoanOro(props)
   
   // Castellarnau
   case 'a8024893': 
   return EnrolmentFormAuthorizationsCastellarnau(props)

   // Cavall Bernat
   case 'a8053251': 
   return EnrolmentFormAuthorizationsCavallBernat(props)

   default: //error
  }
}