import React from 'react'
//import dadesbancaries from '../data/dadesbancaries.json'
//import assignatures from '../data/assignatures.json'
import llista_bonificacions from '../../../data/llista_bonificacions.json'
import config from '../../../config';

const dadesbancaries = require('../../../data/' + config.dadesbancaries)
let assignatures = require('../../../data/' + config.assignatures)

export function calculQuota(curs, assignaturesTriades, dataNaixement, repetidor, setRepetidor, materials, setMaterials, setNivellMax, bonificacio, torn, iddocument, becat ) {

let quota = {
             matricula: 0,
             asseguranca: 0,
             material: 0,
             formacio: 0,
             bonificacio: 0,
             total: 0,
             dadesbancaries: dadesbancaries }

/* controls que impedeixen que es calcul la quota sense tenir les dades corresponents necessaries per al càlcul */
if(dataNaixement === undefined) return quota
if(curs === undefined) return quota
if(curs?.id === undefined) return quota

// busquem nivell maxim de assignatura (només cal que en tinguin una de 2n per ser matricula de 2n curs)
let nivellMax = 0
const llistaAssignatures = assignatures[curs.id]
  llistaAssignatures.map((ll) => {
    ll.subjects.map((s) => { 

      if(assignaturesTriades.includes(s.id))
      {
          if(s.level > nivellMax)
          {
              nivellMax = s.level
          }
        }
    }
    )
  })

  setNivellMax(nivellMax)

  //if(nivellMax === 2 && !props.nouAlumne ) setRepetidor(2)



let temps= new Date()
temps.setFullYear(new Date().getFullYear() - 28)

  quota.menor28 = dataNaixement.getTime() >= temps.getTime() ? true : false

  const quotaAsseguranca = (menor28) => {

    if (menor28) {
      return 1.12

    }
      
    return 0
  }

  const quotaMaterial = (curs) => {

    if (curs.material === undefined ) return 0

    if(materials.length === 0 )  // inicial
    {
      let mats = []

      curs.material.map((m) => 
      { 
            return mats.push({...m, checked: m.check })
      })

      setMaterials(mats)
    }


    let totalMaterials =  0

      materials.map((m) => 
      { 

          if(m.checked) totalMaterials += m.preu
            
      })

      
    return totalMaterials
  }


  const quotaBonificacio = (matricula,bonificacio) => {

    const bon = llista_bonificacions.filter(b => b.id === bonificacio ).pop()

    if (bon) {

      switch (bon.descompte) {
        case '50%': bonificacio = matricula / 2; break;
        case '100%': bonificacio = matricula; break;
        default: bonificacio = 0;
      }

    }

    return bonificacio
  }




// iniciem a 'null'
let formacio = undefined

switch(curs.id)
{
  /*
  { "id": "CFPM1601", "value": "CFGM de Cures auxiliars d’infermeria", "condicions": 0, "torns": [ ["M","T"] ] },
  { "id": "CFPMQU20", "value": "CFGM Operacions de laboratori", "condicions": 0, "torns": [ ["M"] , ["M"]]  }
  { "id": "CFPMTM20", "value": "CFGM de Carrosseria", "condicions": 0, "torns": [ ["M","T"] , ["M","T"]]  },
  { "id": "CFPMTM10", "value": "CFGM d’Electromecànica de Vehicles", "material": [ { "id": "Electude", "preu": 45 } ], "condicions": 0 , "torns": [ ["M","T"] , ["M","T"]]  }
  { "id": "CFPMCM10", "value": "CFGM Activitats Comercials", "condicions": 0, "torns": [ ["M"] , ["M"]]  },
  { "id": "CFPMAG10", "value": "CFGM Gestió Administrativa", "condicions": 0, "torns": [ ["M"] , ["M"]]  }
  { "id": "CFPSSAD0", "value": "CFGS Documentació i Administració Sanitària", "condicions": 0, "torns": [ ["M","T"] , ["M","T"] ] },
  { "id": "CFPSQUD0", "value": "CFGS Laboratori d'anàlisi i control de qualitat", "condicions": 0, "torns": [ ["T"] , ["T"]  ] }
  { "id": "CFPSTMA0", "value": "CFGS Automoció", "material": [ { "id": "Electude", "preu": 45 } ], "condicions": 0 , "torns": [ ["T"] , ["T"]]  }
  { "id": "CFPSCMD0", "value": "CFGS Màrqueting i Publicitat", "condicions": 0, "torns": [ ["T"] , ["T"]]  },
  { "id": "CFPSCMB0", "value": "CFGS Comerç internacional", "condicions": 0, "torns": [ ["T"] , ["T"]]  }
  */

     //CFGM de Cures auxiliars d’infermeria 
     case "CFPM1601": 
     //CFGM de Cures auxiliars d’infermeria  - pla antic
     case "CFPM1601A": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {
                              quota.matricula = 0;

                              // Alumne s'acull a pla nou
                        } else if (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") {
                            quota.matricula = 100;
                        } else{
                          // matricula
                          /*
                          if( nivellMax === 2 ) {
                              quota.matricula = 50;
                          }
                          else {
                              quota.matricula = 85;
                          }
                          // repetidor
                          if( repetidor ) {
                              quota.matricula = 50;
                          }
                          */

                            //Alumnat amb 4 o menys UF matriculades		
                            if (assignaturesTriades.length <= 4 && curs.id.endsWith('A')) {
                              //quota.matricula = 45;
                              quota.matricula = 50;
                            }
                            else // Alumnat amb més de 4 UF matriculades
                            {
                              //quota.matricula = 85;
                              quota.matricula = 100;
                            }

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        }

                        quota.total += quota.matricula

                        // total
                        return quota;

     //CFGM de Farmàcia i parafarmàcia
     case "CFPMSA20": 
     case "CFPMSA20A": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {

                              quota.matricula = 0;


                              // Alumne s'acull a pla nou
                        } else if (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") {
                            quota.matricula = 100;
                        } else{
                          /*
                          // matricula
                          if( assignaturesTriades.length <= 4 ) {
                              quota.matricula = 40;
                          }
                          else {
                              quota.matricula = 80;
                          }
                          */

                          //Alumnat amb 4 o menys UF matriculades		
                          if(assignaturesTriades.length <= 4 && curs.id.endsWith('A'))
                          {
                                //quota.matricula = 45; 
                                quota.matricula = 50;
                          }
                          else // Alumnat amb més de 4 UF matriculades
                          {
                                //quota.matricula = 85;
                                quota.matricula = 100;
                          }

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material
                        }

                        quota.total += quota.matricula


                        // total
                        return quota;

    // CFGM d’Emergències sanitàries
     case "CFPMSA30": 
     case "CFPMSA30A": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {

                              quota.matricula = 0;

                        } else if (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") {
                            quota.matricula = 100;
                        } else{
                          // matricula

                          //estudiants que es volen acollir al pla nou d'estudis

                            //Alumnat amb 4 o menys UF matriculades		
                            if (assignaturesTriades.length <= 4 && curs.id.endsWith('A')) {
                              //quota.matricula = 45;
                              quota.matricula = 50;
                            }
                            else // Alumnat amb més de 4 UF matriculades
                            {
                              //quota.matricula = 85;
                              quota.matricula = 100;
                            }

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material
                        }

                        quota.total += quota.matricula


                        // total
                        return quota;

    //CFGM de Carrosseria
     case "CFPMTM20": 
     case "CFPMTM20A": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {

                              quota.matricula = 0;

                              // Alumne s'acull a pla nou
                        } else if (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") {
                            quota.matricula = 100;
                        } else{
                          // matricula

                          //console.log("XXX",curs.id)

                          //Alumnat amb 4 o menys UF matriculades		
                          if(assignaturesTriades.length <= 4 && curs.id.endsWith('A'))
                          {
                            //console.log("XXX A")
                            //quota.matricula = 40;
                            //quota.matricula = 45;
                            quota.matricula = 50;
                          }
                          else // Alumnat amb més de 4 UF matriculades
                          {
                            //console.log("XXX NO A")
                            //quota.matricula = 80;
                            //quota.matricula = 85;
                            quota.matricula = 100;
                          }

                          // material
                          quota.material = quotaMaterial(curs)
                          quota.total += quota.material
                        }

                        quota.total += quota.matricula


                        // total
                        return quota;
    //CFGM d’Electromecànica de Vehicles Automòbils
     case "CFPMTM10": 
     case "CFPMTM10A": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {

                              quota.matricula = 0;

                              // Alumne s'acull a pla nou
                        } else if (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") {
                            quota.matricula = 100;
                        } else{
                          // matricula

                          //Alumnat amb 4 o menys UF matriculades		
                          if(assignaturesTriades.length <= 4 && curs.id.endsWith('A'))
                          {
                              //quota.matricula = 40;
                              //quota.matricula = 45;
                                quota.matricula = 50;
                          }
                          else // Alumnat amb més de 4 UF matriculades
                          {
                              //quota.matricula = 80;
                              //quota.matricula = 85;
                                quota.matricula = 100;
                          }

                          // material
                          quota.material = quotaMaterial(curs)
                          quota.total += quota.material
                        }

                        quota.total += quota.matricula


                        // total
                        return quota;

    //CFGM d’Electromecànica de Vehicles Automòbils, perfil professional d’Electromecànica de Vehicles Industrials
     case "CFPMTM12": 
     case "CFPMTM12A": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {

                              quota.matricula = 0;

                              // Alumne s'acull a pla nou
                        } else if (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") {
                            quota.matricula = 100;
                        } else{
                          // matricula

                          //Alumnat amb 4 o menys UF matriculades		
                          if(assignaturesTriades.length <= 4 && curs.id.endsWith('A'))
                          {
                              //quota.matricula = 40;
                              //quota.matricula = 45;
                              quota.matricula = 50;
                          }
                          else // Alumnat amb més de 4 UF matriculades
                          {
                              //quota.matricula = quota.menor28 ? 78.88 : 80;
                              //quota.matricula = 85;
                              quota.matricula = 100;
                          }


                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material
                        }

                        quota.total += quota.matricula

                        // total
                        return quota;
    //CFGS Projectes d’edificació (Inclou el perfil professional en rehabilitació i restauració d’edificis)
     case "CFPSEOB1A":
     case "CFPSEOB1": formacio = formacio === undefined ? 90 : formacio 

     //CFGS d’Audiologia protètica
     case "CFPSSAG0A":
     case "CFPSSAG0": formacio = formacio === undefined ? 90 : formacio

     //CFGS de Dietètica 
     case "CFPS1651A":
     case "CFPS1651": formacio = formacio === undefined ? 90 : formacio

     // CFGS d’Imatge per al diagnòstic i medicina nuclear
     case "CFPSSAI0A":
     case "CFPSSAI0": formacio = formacio === undefined ? 90 : formacio

     // CFGS de Laboratori clínic i biomèdic
     case "CFPSSAE0A":
     case "CFPSSAE0": formacio = formacio === undefined ? 115 : formacio

     //CFGS Química i Salut Ambiental
     case "CFPSSMC0A":
     case "CFPSSMC0": formacio = formacio === undefined ? 90 : formacio

     //￼CFGS Automoció
     case "CFPSTMA0A":
     case "CFPSTMA0": formacio = formacio === undefined ? 100 : formacio

                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {

                          //CFGS de Dietètica  // son 65 per credit
                          if (curs.id === "CFPS1651" || curs.id === "CFPS1651A") {
                            quota.matricula = 65;
                          }
                          else {
                            quota.matricula = 25;
                          }

                        } else{



// NYAP, AQUESTA ASSIGNATURA DEPEN DE SI ES TORN MATI O TARDA PER SI ES DE PRIMER O SEGON
  //110.01", "level": 2, "name": "UF1. Empresa i iniciativa emprenedora", "hours": 66 , "assign": "CFPSSAE0" 

  // SI ES CANVIA AIXÒ CAL CANVIAR-HO TAMBÉ A STUDIES.JS


  if (curs.id === "CFPSSAE0" || curs.id === "CFPSSAE0A") {

    //console.log('lla', llistaAssignatures)
    assignatures[curs.id].map((lla) => {
      if (lla.id === "110") {

        lla.subjects.map((sub) => {

          if (sub.id === "110.01") {

            if (torn === "M" && sub.level !== 1) {
              sub.level = 1
            }

            if (torn === "T" && sub.level !== 2) {
              sub.level = 2
            }
          }
        })
      }
    })
  }


                          const llistaAssignaturesPrimer =  []
                          const llistaAssignaturesSegon =  []

                          assignatures[curs.id].map( (bloc) => 
                          { 
                            bloc.subjects.map( 
                                (assignatura) => { 

                                                                      // Les FCT de primer no computen com a assignatura 
                                        if(assignatura.level === 1 && !/^FCT/.test(assignatura.id)) { llistaAssignaturesPrimer.push(assignatura.id)  } 
                                        if(assignatura.level === 2) { llistaAssignaturesSegon.push(assignatura.id)  } 
                                      }
                                              )
                          }
                          )

                          //console.log(llistaAssignaturesSegon)

                          let matricula_parcial_1r = true;
                          let matricula_parcial_2n = true;
                          if(llistaAssignaturesPrimer.length>0)
                          {
                            let copiaAssignatures1r = [...llistaAssignaturesPrimer]

                            matricula_parcial_1r = copiaAssignatures1r.filter( (assignatura) =>  !assignaturesTriades.includes(assignatura) ).length === 0 ? false : true 
                          }

                          if(llistaAssignaturesSegon.length>0)
                          {
                            let copiaAssignatures2n = [...llistaAssignaturesSegon]

                            matricula_parcial_2n = copiaAssignatures2n.filter( (assignatura) =>  !assignaturesTriades.includes(assignatura) ).length === 0 ? false : true 
                          }

                          /*
                          // 2n de CFGS de Laboratori clínic i biomèdic (CFPSSAE0A) sempre és preu complet
                          if (curs.id === "CFPSSAE0" || curs.id === "CFPSSAE0A") {
                            matricula_parcial_2n = false
                          }
                            */


                          //console.log("PARCIAL 1",matricula_parcial_1r)
                          //console.log("PARCIAL 2",matricula_parcial_2n)

                          if( matricula_parcial_1r && matricula_parcial_2n)
                          {
                            let preu = 25;

                            //CFGS de Dietètica  // son 65 per credit
                            if(curs.id === "CFPS1651" || curs.id === "CFPS1651A")
                            {
                              preu = 65;
                            }

                              // Les FCT de primer no computen com a assignatura a pagar
                            quota.matricula =  preu * assignaturesTriades.filter( (ass) => !/^FCT$/.test(ass)).length

                              // L'Alumne s'acull a pla nou
                           if (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") {
                            quota.matricula = 360;
                           }

                          }
                          else
                          {
                              quota.matricula = 360
                          }

                        }
                        
                        if(quota.matricula > 360) quota.matricula = 360

                        // bonificacio
                        if(curs.bonificacions === true )
                        {
                          quota.bonificacio = quotaBonificacio(quota.matricula,bonificacio)
                        }

                        // sumen al total despres de bonificacions
                        quota.total += ( quota.matricula - quota.bonificacio )


                        if(assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0]) ) {
                          //nomes FCT no paguen formacio ni tampoc tenen material

                          //despeses de formació
                          quota.formacio = 0
                          quota.total += quota.formacio

                          quota.material = 0
                          quota.total += quota.material
                        }
                        else
                        {

                          // 4 UF o menys 40€ sinó 65 (o la que tingui per curs)
                          //despeses de formació
                          quota.formacio = ( assignaturesTriades.length <= 4 && curs.id.endsWith('A') ) ? 40 : formacio

                            // l'Alumne s'acull al canvi de pla nou
                          quota.formacio = (assignaturesTriades.length === 1 && curs.id.endsWith('A') && assignaturesTriades[0] === "ONLY") ? formacio : quota.formacio

                          quota.total += quota.formacio
                          
                          // material
                          quota.material = quotaMaterial(curs)
                          quota.total += quota.material
                        }

                        // total
                        return quota;

            // Curs d’especialització de cultius cel·lulars.
            case "CE1":       
            // Transport i manteniment de vehicles
            case "CE2":       
                        quota.matricula = 0 

                        quota.material = quotaMaterial(curs)
                        quota.total += quota.matricula + quota.material

                        // total
                        return quota; 

     default: return quota;
      
}

}


/*****************************************
 * 
 * MostraQuota
 * 
 * 
 * 
 */

export const MostraQuota = (props)  => {

let quota = props.quota

const handleChangeRepetidor = (v) => {

  props.setRepetidor(v)

  //checkMaterials()
}


const canviaPreuMaterial = (id,preu) => {

let mat = [...props.materials]

  mat.filter(item => item.id === id).map( (m) => m.preu = preu )

  props.setMaterials(mat)

}

const canviaTxtMaterial = (id,txt) => {

let mat = [...props.materials]

  mat.filter(item => item.id === id).map( (m) => m.txt = txt )

  props.setMaterials(mat)

}


const handleChangeMaterial = (t) => {

let mat = [...props.materials]

  mat.filter(item => item.id === t).map( (i) => i.checked = !i.checked )

  props.setMaterials(mat)

}

const isMaterialChecked = (id) => {

let mat = props.materials.filter(item => item.id === id).pop()

if(mat === undefined ) return false
return mat.checked
}


const handleChangeBonificacions = (t) => {

  handleChangeDosTerminis(false)
  //console.log(t)
  props.setBonificacions(t)
}

const handleChangeDosTerminis = (t) => {

  props.setDosTerminis(t)

}


  if (props.dosTerminis) {
    quota.termini1 = quota.total - ( quota.matricula / 2) 
    quota.termini2 = quota.matricula  / 2
  }
  else {
    quota.termini1 = undefined
    quota.termini2 = undefined
  }


// calcul quotes  / terminis
//console.log("dos", props.dosTerminis);

let enguany = new Date().getFullYear()
let anyVinent = enguany + 1
anyVinent = anyVinent.toString().slice(-2)

  return (
    <div className="col col-12">

      <div className="row">
        <div className="t-Form-labelContainer">
          <label className="t-Form-label"> Quota de pagament del curs escolar {enguany}/{anyVinent}</label>
        </div>
      </div>

      <div className="row">
      <div className="col quota">


        <div className="quotaTitol">Curs: {props.curs.value} ({props.curs.id})</div>

        { quota.total >0 && ( quota.dadesbancaries.IBAN && (<div className="quotaDadesBancaries">

          <div><span className="quotaNomCamp">Entitat bancària:</span> {quota.dadesbancaries.entitat}</div>

          <div> <span className="quotaNomCamp">nº IBAN:</span> {quota.dadesbancaries.IBAN}</div>

          <div className="quotaDadesPagamentCalendari"><span className="quotaNomCamp">Condicions:</span> {quota.dadesbancaries.condicions[props.curs.condicions]}</div>

        </div>) ) }

        <label> Conceptes </label>

        <table className="quota table">


          <tr>
            <th className="quotaNomCamp" colspan="2">Assegurança Escolar Obligatòria: </th>
          </tr>

          <tr>
            { quota.menor28 && (<td className="col">Alumnat menor de 28 anys</td>)}
            {!quota.menor28 && (<td className="col">Alumnat de 28 anys i major</td>)}
            <td>{(quota.asseguranca).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>
          </tr>

          {props.nouAlumne !== true && (
          <>
                {  props.error.repetidor && (<tr>
                  <td colspan="2">
                    <span id="repetidor1_ERROR" className="a-Form-error">{props.error.repetidor}</span>
                  </td>
                </tr>)}
          <tr>
            <td colspan="2"><input type="radio" id="repetidor0" name="repetidor" value="0" defaultChecked={ props.repetidor === 0 } onChange={(e) => handleChangeRepetidor(0)} ></input>
             <label for="repetidor0">Promociona a 2n</label></td>
          </tr>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor1" name="repetidor" value="1" defaultChecked={ props.repetidor === 1 } onChange={(e) => handleChangeRepetidor(1)} ></input>
             <label for="repetidor1">Repetidor 1r</label>
             <span id="repetidor1_ERROR" className="a-Form-error">{props.error.repetidorsNoPodenAgafarAssignaturesDe2n}</span>
             </td>

          </tr>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor2" name="repetidor" value="2" defaultChecked={ props.repetidor === 2 } onChange={(e) => handleChangeRepetidor(2)} ></input>
             <label for="repetidor2">Repetidor 2n</label></td>
          </tr>
          </>
          )
          }

          <tr>
            <td>Matriculació al curs:</td>
            <td>{(quota.matricula).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
            </td>
          </tr>


          {props.curs?.bonificacions === true && (<tr><th colspan="2" className="quotaNomCamp">Bonificacions / Exempcions:</th></tr>)}


          {props.curs?.bonificacions === true && (
                <tr>
                  <td>
                    <select id="bonificacions" name="bonificacions" onChange={(e) => handleChangeBonificacions(e.target.value)}>
                      {llista_bonificacions.map((m, k) =>
                        <option key={k} id={m.id} name={m.id} value={m.id}>{m.valor} ({m.descompte})</option>
                      )}
                    </select>
                  </td>
                  <td>
                    {quota.bonificacio >0 && "-"+(quota.bonificacio).toLocaleString(undefined, { 'maximumFractionDigits': 2 })+ "€" }
                  </td>
                </tr>
          )}

          { quota.formacio > 0 && (<tr><td className="quotaNomCamp">Despeses de formació:</td><td>{(quota.formacio).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td></tr>)}

          {!props.curs.material && (<tr><td className="quotaNomCamp">Material específic del cicle:</td><td>0&euro;</td> </tr>)}
          {props.curs.material && (<tr><th colspan="2" className="quotaNomCamp">Material específic del cicle:</th></tr>)}

          {props.curs.material && props.curs.material.map((m, p) => {

            let disabled = false

            if ( props.assignatures?.length === 1 && /^FCT/.test(props.assignatures[0]) ) { // si només fan FCT no hi ha mai material
                return false
            } 
            else
            {

            //ELECTUDE
            if(m.id=== "electude")
            {
              switch (props.curs.id) {

                //  CFGM d’Electromecànica de Vehicles Automòbils, perfil professional d’Electromecànica de Vehicles Industrials
                case 'CFPMTM12A': 
                case 'CFPMTM12': // obligatori sempre excepte FCT ;  2n any /repetidors canvia preu

                // CFGS Automoció
                case 'CFPSTMA0A':
                case 'CFPSTMA0': // obligatori sempre excepte FCT i repetidors

                //  CFGM d’Electromecànica de Vehicles Automòbils, perfil professional d’Electromecànica de Vehicles Industrials
                case 'CFPMTM12A':
                case 'CFPMTM12': // obligatori sempre excepte FCT i repetidors

                // CFGM d’Electromecànica de Vehicles Automòbils 
                case 'CFPMTM10A':
                case 'CFPMTM10':  // obligatori sempre excepte FCT i repetidors

                /*
                                  if( props.repetidor > 0 && ( 
                                                             props.assignatures?.length <= 4 || ( props.assignatures?.length === 1 && /^FCT/.test(props.assignatures[0]) )
                                                         )
                                    )
                                    {
                                      disabled = false; 
                                      break;
                                    }
                                    */

                                  if( props.repetidor > 0 )
                                  {
                                    if(m.preu !== 0)
                                    {
                                      m.preu = 0
                                      canviaPreuMaterial(m.id,m.preu)
                                    }
                                  }
                                  else
                                  {
                                    if(m.preu !== 75)
                                    {
                                       m.preu = 75
                                      canviaPreuMaterial(m.id,m.preu)
                                    }
                                  }

                                  disabled = true; 
                                  break; 
                default:
                                  disabled = true; 
                                  break; 

              }
            }

            // material dels CE
            if(m.id=== "material")
            {
              disabled = true;
            }

          }

          return (<tr>
            <td>
            <input type="checkbox" id={m.id} name={m.id} value={m.preu} checked={isMaterialChecked(m.id)} disabled={disabled} onChange={(e) => handleChangeMaterial(e.target.id)} />
            <label for={m.id}>{m.txt}</label>
            </td>
            <td>
            {m.preu}&euro;
          </td></tr>)}
          )}

          <tr><th className="quotaNomCamp" colspan="2"> Total a abonar a compte:</th></tr>

          <tr>
          { quota.menor28 && (<td>Alumnat menor de 28 anys</td>)}
          {!quota.menor28 && (<td>Alumnat de 28 anys i major:</td>)}
           <td className="quotaTotal"> {(quota.total).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>
          </tr>

         { /* 100% bonificat no permet pagament en dos terminis*/ }

          { ( props.curs.condicions === 1 && quota.matricula-quota.bonificacio>0 ) && (<tr>
            <td colspan="2">
            <input type="checkbox" id="dosTerminis" name="dosTerminis" value={true} checked={props.dosTerminis} onChange={(e) => handleChangeDosTerminis(e.target.checked)} />
            <label for="dosTerminis">Vull fer el pagament en 2 terminis </label>
            </td>
            <td>
          </td></tr>)}


           { props.dosTerminis && (
              <React.Fragment>
              <tr>
                <td>
                  Pagament 1r termini:
                </td>
                <td>
                  { quota.termini1 && (quota.termini1).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
                </td>
              </tr>
              <tr>
                <td>
                  Pagament 2n termini:
                </td>
                <td>
                  { quota.termini2 && (quota.termini2).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
                </td>
              </tr>
              </React.Fragment>
            )}

        </table>

      </div>
      </div>

    </div >
  )
  
 }
