// config.castellarnau.js

const config = {
  cid: 'a8024893',
  phone: "93 726 94 22",
  email: "secretaria@institutcastellarnau.cat",

  headerLogo:  'img/logo_bn_castellarnau.png',
  headerTitle:  'Institut Castellarnau',
  headerURL: 'https://matricula.institutcastellarnau.cat/',

  enrollmentPostURL: '/post/',
  apiURL: '/api/',

  validaIDdocuments: true,
  tipusDocTSI: false,

  fotoCarnet: true,    
  
  contacteEmergenciaObligatori: false,

  adjuntMedic: false,
  adjuntMedicObligatori: false,

  datesNoBloquejenInscripcio: false,

  mostraTorns: true,

  logo: 'img/logo_castellarnau.jpg',
  titol: 'IES Castellarnau',

  favicon: '/img/favicon-castellarnau.ico',
  faviconGestio: '/gestio/img/favicon-castellarnau.ico',

  assignatures: 'assignatures.castellarnau.json',
  families: 'families.castellarnau.json',
  dadesbancaries: 'dadesbancaries.castellarnau.json',
  calculquota: 'quotes.castellarnau.json',   

  treballantFile: false, 
  necessitatsEducativesEspecials: true,

  repetidor: true,
  repetidorsNoPodenAgafarAssignaturesDe2n: true,

  textDadesMediquesAlgunSi: "S’haurà de portar una fotocòpia de la patologia esmentada mitjançant un informe mèdic i donar-li al tutor/a de grup el dia de la presentació.",

  textBotoMatricular: "MATRÍCULA",

  textAlumneNouAlCentre: "Ets alumne/a nou/nova al nostre centre?",
  textAlumneNouAlCentreSub: null,

  urlTPV: "",
  pagamentTPV: false,
  pagamentTransferencia: true,

  sexeNoBinari: false,
};

export default config;