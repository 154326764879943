import React from 'react';
import { createStore } from 'redux'; 
import { Provider } from 'react-redux'; 
import reducer from './reducers'
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const store = createStore(reducer);

/*
import { BrowserRouter, Routes, Route } from "react-router-dom";

  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="hello" element={<Hello />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
 */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <Provider store={store}>
      <App />
   </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
