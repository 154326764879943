// config.joanoro.js

const config =
{
    cid: 'a8037152',
    phone: "93 775 36 18",
    email: "a8037152@xtec.cat",

    headerLogo:  'img/logo_bn_joanoro.png',
    headerTitle:  'Institut Joan Oró',
    headerURL: 'https://matricula.institutjoanoro.cat/',

    //enrollmentPostURL: 'https://matricula.institutjoanoro.cat/post/',
    enrollmentPostURL: '/post/',
    //apiURL: 'https://matricula.institutjoanoro.cat/api/',
    apiURL: '/api/',

    validaIDdocuments: true,  
    tipusDocTSI: true,
    tipusDocPreInscripcio: true,

    fotoCarnet: false,

    contacteEmergenciaObligatori: false,

    adjuntMedic: false,
    adjuntMedicObligatori: false,

    datesNoBloquejenInscripcio: true,

    mostraTorns: true,

    logo: 'img/logo_joanoro.jpg',
    titol: 'IES Joan Oro',

    favicon: '/img/favicon-joanoro.ico',
    faviconGestio: '/gestio/img/favicon-joanoro.ico',

    assignatures: 'assignatures.joanoro.json',
    families: 'families.joanoro.json',
    dadesbancaries: 'dadesbancaries.joanoro.json',
    calculquota: 'quotes.joanoro.json',

    /* camps especifics per centre */
    treballantFile: true,
    necessitatsEducativesEspecials: true,

    repetidor: false,
    repetidorsNoPodenAgafarAssignaturesDe2n: false,

    textDadesMediquesAlgunSi: "",

    textBotoMatricular: "INSCRIURE",

    textAlumneNouAlCentre: "Ets alumne nou als estudis que et matricules?",
    textAlumneNouAlCentreSub: "(Si has fet una preinscripció per Internet per a aquest curs, marca SÍ, per la resta de casos cal indicar NO)",

    urlTPV: "https://matricula.institutjoanoro.cat/tpv/",
    pagamentTPV: true,
    pagamentTransferencia: false,

    sexeNoBinari: false,
  };

export default  config;