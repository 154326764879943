import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { MostraQuota, calculQuota } from './quotes'
import Dropdown from '../dropdown';
import { FormInputText, FormSelectRadio, FormSelectCicle, FormSelectNouAlumne, FormTextArea } from './formelements';
//import assignatures from '../../data/'+config.assignatures;
//import families from '../../data/'+config.families;
import { useDropzone } from 'react-dropzone'
import config from '../../config';
const assignatures = require('../../data/' + config.assignatures)
const families  = require('../../data/' + config.families)

//DEBUG console.log("FAMILIES XXX ",config.families,families)
//DEBUG console.log("ASSIGNATURES ",assignatures)

const TriaAssignatures = (props) => {

  const llistaAssignatures = assignatures[props.curs.id]

  // NYAP, AQUESTA ASSIGNATURA DEPEN DE SI ES TORN MATI O TARDA PER SI ES DE PRIMER O SEGON
  //110.01", "level": 2, "name": "UF1. Empresa i iniciativa emprenedora", "hours": 66 , "assign": "CFPSSAE0" 


 // SI ES CANVIA AIXÒ CAL CANVIAR-HO TAMBÉ A CALCULQUOTES


  if (props.curs.id === "CFPSSAE0" || props.curs.id === "CFPSSAE0A") {

    //console.log('lla', llistaAssignatures)
    llistaAssignatures.map((lla) => {
      if (lla.id === "110") {

        lla.subjects.map((sub) => {

          if (sub.id === "110.01") {

            if (props.torn === "M" && sub.level !== 1) {
              sub.level = 1
            }

            if (props.torn === "T" && sub.level !== 2) {
              sub.level = 2
            }
          }
        })
      }
    })
  }


  
  const [isCheckAll, setIsCheckAll] = useState([false, false, false]); // level = 0,1,2
  const [isCheck, setIsCheck] = useState([]);
  const [only, setOnly] = useState(false);


  useEffect(() => {

  setIsCheckAll([false, false, false]);
  setIsCheck([]);
  setOnly(false)

  },[props.curs.id]) // si canvia el curs, tornema inicialitzar assignatures triades


  //TODO
  const handleSelectAllModule = (mod) => {
    /*

    let newA = [...isCheckAll];

    newA[mod] = !newA[mod];

    setIsCheckAll(newA);

    let allList = []

    if (!isCheckAll[mod]) {

      llistaAssignatures.map((assignatura) => assignatura.subjects.filter(item => item.level === mod).map((subject) => { return allList.push(subject.id) }))

    }

    setIsCheck(allList)
    */
  };

  
  const handleSelectAllLevel = (level) => {

    let newA = [...isCheckAll];

    newA[level] = !newA[level];

    setIsCheckAll(newA);

    let allList = []

    if (!isCheckAll[level]) {

      llistaAssignatures.map((assignatura) => assignatura.subjects.filter(item => item.level === level).map((subject) => { return allList.push(subject.id) }))

    }

    setIsCheck(allList)
  };

  const handleClickOnSubject = (id, checked, XOR) => {

    // Si és assignatura única, forcem només aquesta i amagem les altres, o mostrem les altres al desmarcar.
    if (id === "ONLY") {

      if (checked) {
        setIsCheck([])
        setOnly(false)
        props.setRepetidor()
      }
      else
      {
        setIsCheck([id])
        setOnly(true)
        props.setRepetidor(1)
      }

      return
    }


    if (checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
    else {

      if (XOR) {  // si l'assignatura és "exclusiva" marca només aquesta eliminant la resta

        //console.log(id)
        
        if( XOR === true)
        { 
          setIsCheck([id])  // XOR true és assignatura unica de totes
        }
        else // XOR amb numero es un unic per grup númeric 1,2,3,4,...
        {
          let isCheckDup = []
          let sameXOR = []

          //console.log("XOR",XOR,id,checked)

            llistaAssignatures.map((assignatura) => 
              assignatura.subjects.filter(item => item.XOR === XOR ).map((subject) => { 

                //console.log(subject.id,subject.XOR," === ",XOR)
                return sameXOR.push(subject.id) 
              }))

          //console.log("sameXOR",sameXOR)
          isCheckDup = isCheck.filter(item => !sameXOR.some( item2 => item2 === item ) )

          setIsCheck([...isCheckDup, id])
        }


      } else {

        let isCheckDup = [...isCheck]

        // assignatures de CFGS Audiologia que son excloents
        if (id === "108.02 I") { isCheckDup = isCheckDup.filter(item => item !== "108.02 II") }
        if (id === "108.02 II") { isCheckDup = isCheckDup.filter(item => item !== "108.02 I") }

        setIsCheck([...isCheckDup, id])
      
      }
    }
    
  }

  const handleClickOnTheme = (theme) => {

    let isCheckDup = [...isCheck]

    theme.subjects.map((subject, index) => {

    //console.log("THEME", isCheck.includes(subject.id), subject)

    if (isCheck.includes(subject.id)) {
      isCheckDup = (isCheckDup.filter(item => item !== subject.id));
    }
    else {

      // assignatures de CFGS Audiologia que son excloents
      if(subject.id === "108.02 I")  { isCheckDup = isCheckDup.filter(item => item !== "108.02 II") }
      if(subject.id === "108.02 II") { isCheckDup = isCheckDup.filter(item => item !== "108.02 I") }

      isCheckDup = [...isCheckDup, subject.id]
    }

    setIsCheck(isCheckDup)

    } )

    
  }
  
  
  useEffect((e) => {
    
    props.handleChange(isCheck)
    
  }, [isCheck])


  /* si només hi ha una assignatura, la marquem per defecte */
  useEffect((e) => {
  if(llistaAssignatures.length === 1 )
  {
    if (llistaAssignatures[0].subjects.length === 1) {
       handleClickOnSubject(llistaAssignatures[0].subjects[0].id,false,true)
    }
  }

},[llistaAssignatures])
  

  let idx_torns = 0;
  if(props.nivellMax>0)  idx_torns = props.nivellMax - 1
  const torns = [{id: "M", value: "Matí"},{id: "T", value: "Tarda"}]

  let tornsenabled = props.curs.torns[idx_torns]

  // forcem al torn obligatori si ens ha vingut donat al moment de comprovar DNI
  if(props.tornObligatori) 
  { 
    tornsenabled = [props.tornObligatori]
  }

  //console.log("TORNS ENA",props.tornObligatori,tornsenabled)
  
  return (<div className="col col-12">

    <br/>

    <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

      <div className="t-Form-labelContainer">
        <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">Tria d'assignatures</label>

        {( ( props.curs.id.startsWith("ESO") || props.curs.id.startsWith("BATX") ) && llistaAssignatures.length > 1 ) && (<><br/><small>(Heu de triar una de cada bloc)</small></>)}
      </div>



      <div className="t-Form-inputContainer">

        <div className="t-Form-itemWrapper">

          <div className="col col-3 ">
            {(llistaAssignatures.length > 1 && config.mostraTorns ) && (
              <FormSelectRadio elements={torns} enabledelements={tornsenabled} col=" col-12" name="torn" value={props.torn} label="Torn" handleChange={props.handleCanviTorn} />
            )}
          </div>

          <div className="col col-1 "> &nbsp;</div>

            { (llistaAssignatures.length > 1 && ( !props.curs.id.startsWith("ESO") && !props.curs.id.startsWith("BATX") )) && (
                <div className="col col-4 ">
                <label className="t-Form-label">Tria totes les assignatures del curs:</label>
                <table border="0" className="subjectsTable">

                  <tr className="level_1" onClick={() => handleSelectAllLevel(1)}>
                    <td>
                      <label className="t-Form-label">1r curs tot el curs</label>
                    </td>
                  </tr>

                  <tr className="level_2" onClick={() => handleSelectAllLevel(2)}>
                    <td>
                      <label className="t-Form-label">2n curs tot el curs</label>
                    </td>
                  </tr>
                </table>
              </div>
              )}



          <table border="0" className="subjectsTable">

            {/*
            <tr>
                <th></th>
                <th className="">Codi</th>
                <th className="">Assignatura</th>
                <th className="">Hores</th>
                <th className="">Codi curs</th>
            </tr>
*/}

            {llistaAssignatures.map((theme, index) =>
            (
              <React.Fragment>

                  {
                     (theme.name.length > 0) && (
                <tr style={{ display: (only) ? "none" : "table-row" }} >
                  <th colspan="1">
                  </th>
                  <th colspan="2">
                     { 
                     ( props.curs.id.startsWith("ESO") || props.curs.id.startsWith("BATX") ) ?
                      (<label for={theme.id} id={theme.id + '_LABEL'} className="t-Form-label"><b>{theme.name}</b></label>)
                      :
                      (<label for={theme.id} id={theme.id + '_LABEL'} className="t-Form-label" onClick={() => handleClickOnTheme(theme)}><b>{theme.name}</b></label>) 
                     }
                  </th>
                </tr>
                    )
                  }
                {theme.subjects.map((subject, index) =>
                (<tr key={index} className={'level_' + subject.level} style={{ display: (only && subject.id !== "ONLY") ? "none" : "table-row", backgroundColor: subject.id === "ONLY" ? "lightgrey" : null }} onClick={() => handleClickOnSubject(subject.id, isCheck.includes(subject.id), subject.XOR )}>
              {/*DEBUG console.log("SUB",subject)*/ }

                  <td><input type="checkbox" id={subject.id} name="familia" value={subject.id} checked={isCheck.includes(subject.id)} readOnly /></td>
                  {/* subject.preu !== undefined && (<td for={theme.id} id={theme.id + '_LABEL'} className=""><i>{subject.preu}€</i></td>)*/}
                  {/*<td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.id.split(".").pop()}</td>*/}
                  <td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.name}</td>
                  {/*<td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.hours}</td>
                  <td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.assign}</td>*/}
                </tr>))}
              </React.Fragment>
            ))}

          </table>

        </div>

        <span id="{props.id}_ERROR" className="a-Form-error"></span>

      </div>

    </div>

  </div>)
}



const TriaCurs = (props) => {

  let fam = families[props.cicle].families.filter(f => f.id === props.familia).pop();

  // si el curs te l'atribut nouAlumne només els mostra als que tinguin el mateix valor T/F, mostra a tothom si no està posat
  let cursos = fam.cursos.filter( f => { 
  if( f?.nouAlumne === undefined ) return f 
    else 
      if( f.nouAlumne === props.nouAlumne ) return f 
   })

  //console.log("CURSOS",cursos)

  return (<FormSelectRadio elements={cursos} col=" col-12" name="curs" cursObligatori={props.cursObligatori} value={props.curs?.id} label={props.label} handleChange={props.handleChange} />)

}

const ESO = (props) => {

  return (<FormSelectRadio elements={families.ESO.families} col=" col-3" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}

const BATX = (props) => {

  return (<FormSelectRadio elements={families.BATX.families} col=" col-3" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}

const CFGS = (props) => {

  return (<FormSelectRadio elements={families.CFGS.families} col=" col-2" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}

const CFGM = (props) => {

  return (<FormSelectRadio elements={families.CFGM.families} col=" col-2" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}

const CE = (props) => {

  return (<FormSelectRadio elements={families.CE.families} col=" col-3" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}

const PFI = (props) => {

  return (<FormSelectRadio elements={families.PFI.families} col=" col-3" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}

const IFE = (props) => {

  return (<FormSelectRadio elements={families.IFE.families} col=" col-3" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}

const FPB = (props) => {

  return (<FormSelectRadio elements={families.FPB.families} col=" col-3" name="familia" value={props.familia} label={props.label} handleChange={props.handleChange} />)

}


export default function EnrolmentFormStudies(props) {

  const [errors, setErrors] = useState({});

  const [cicle, setCicle] = useState();
  const [nouAlumne, setNouAlumne] = useState();
  const [nPreinscripcio, setNPreinscripcio] = useState('');
  const [titolAccesFile, setTitolAccesFile] = useState()
  const [familia, setFamilia] = useState();
  const [curs, setCurs] = useState();
  const [quota, setQuota] = useState();
  const [observacionsMatricula, setObservacionsMatricula] = useState();
  const [dadesInscripcioOpen, setDadesInscripcioOpen] = useState(true);
  const [dadesQuotaOpen, setDadesQuotaOpen] = useState(true);
  const [assignatures, setAssignatures] = useState([]);
  const [torn, setTorn] = useState('M')
  const [repetidor , setRepetidor ] = useState()
  const [materials, setMaterials] = useState([])
  const [bonificacio, setBonificacio] = useState([])
  const [dosTerminis, setDosTerminis] = useState(false)
  const [justificantPagamentFile, setJustificantPagamentFile] = useState()
  const [bonificacioFile, setBonificacioFile] = useState()
  const [notesCursAnteriorFile, setNotesCursAnteriorFile] = useState()
  const [nivellMax, setNivellMax] = useState(0);

  const [dadesEstudis, setDadesEstudis] = useState({validat: false});

  const dataNaixement = props.dadesPersonals?.dataNaixement;
  const iddocument = props.dadesPersonals?.iddocument;
  const becat = props.dadesPersonals?.becat;

  const  inValidaEstudis = () => {

    handleDadesEstudis(false)

  }


  const validaEstudis = (e) => {
    e.preventDefault()
  
    let error = {}
  
    error.quota = quota ? null : "Error: quota de pagament no calculada"

    //només obligatori pels nous i de cicles
    if(nouAlumne && (cicle.startsWith('CFGS') || cicle.startsWith('CFGM')))
    {
      error.titolAccesFile = titolAccesFile ? null : "Cal adjuntar títol pel qual s'accedeix"
      handleErrors("titolAccesFile",error.titolAccesFile);
    }
    else
    {
      // TODO  Caldria passar valor al quotes.xxx.js
      //error.repetidor = repetidor !== undefined ? null : "Cal indicar una opció"
      //handleErrors("repetidor",error.repetidor);
    }

    if( config.pagamentTPV && quota.total>0 )
    {
      /*error.justificantPagamentFile = justificantPagamentFile ? null : "Cal adjuntar justificant de pagament"
      handleErrors("justificantPagamentFile",error.justificantPagamentFile);*/
    }

    if( config.pagamentTransferencia && quota.total>0 )
    {
      error.justificantPagamentFile = justificantPagamentFile ? null : "Cal adjuntar justificant de pagament"
      handleErrors("justificantPagamentFile",error.justificantPagamentFile);
    }

    if(quota.bonificacio>0)
    {
      error.bonificacioFile = bonificacioFile ? null : "Cal adjuntar justificant de bonificació / exempció "
      handleErrors("bonificacioFile",error.bonificacioFile);
    }

    if( nouAlumne && nivellMax === 2 )
    {
      error.notesCursAnteriorFile = notesCursAnteriorFile ? null : "Cal adjuntar les notes del curs anterior"
      handleErrors("notesCursAnteriorFile",error.notesCursAnteriorFile);
    }

    console.log("REP 1",config.repetidor,nouAlumne)
    if ( config.repetidor && !nouAlumne )
    {
    console.log("REP 2")
      error.repetidor = repetidor ? null : "Cal indicar si promocioneu o sou repetidors"

    console.log("REP 3",repetidor)
      handleErrors("repetidor",error.repetidor);
    }


    if( config.repetidorsNoPodenAgafarAssignaturesDe2n )
    {
      if( repetidor === 1 && nivellMax === 2 )
      {
        error.repetidorsNoPodenAgafarAssignaturesDe2n = "Si repeteixes 1r no pots agafar assignatures de 2n"
        handleErrors("repetidosNoPoenAgafarAssignaturesDe2n",error.repetidorsNoPodenAgafarAssignaturesDe2n);
      }

    }


    
    setErrors(error)

    let noError = true;

    if(!config.develop) // bypass errors if develop == true
    {
     Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
    }

    handleDadesEstudis(noError)
  
    if(noError)
    {
        setDadesInscripcioOpen(false)
        setDadesQuotaOpen(false)
    }
  
    return false;
  }


  // canvis en dades quota, recaulculem
  useEffect(() => {

    setQuota(calculQuota(curs,assignatures,dataNaixement,repetidor,setRepetidor,materials,setMaterials,setNivellMax, bonificacio, torn, iddocument ));
    inValidaEstudis()

  }, [assignatures,repetidor,dataNaixement,curs,materials, bonificacio, torn]);

  const handleErrors = (nom, error) => {
    //console.log('error', nom, error)
    setErrors(error)
  }

  const handleFileError = (e,msg) => {
    let err  = {...errors}
    err[e] = msg 
    setErrors(err)
  }

function DropZone(props) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files

    handleErrors(props.id, { justificantPagamentFile: null })

    let file = acceptedFiles[0];

      const MAXSIZE = 4
      if (file.size > MAXSIZE * 1024 * 1024 ) {
        props.handleFileError("Arxiu massa gran màxim " + MAXSIZE + "MB")
        file = undefined
        props.onChange() // buidem file al no ser correcte
      }

    if (file) {

       /*if (!/^image/.test(file.type)) {
          setIDDocumentFile(null)
          return handleErrors(props.id, { [props.id]: "Tipus d'arxiu no vàlid" })
        }*/

        //console.log("FT",file.type);
        if (!/^image\/png/.test(file.type) && !/^image\/jpeg/.test(file.type)) {
          file = undefined
          props.onChange() // buidem file al no ser correcte
          props.handleFileError("Tipus d'arxiu no vàlid. S'accepten imatges JPG o PNG.")
          return false
        }

        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          // Do whatever you want with the file contents
          const fileContent = reader.result
          //console.log("BIN", fileContent)

          props.onChange({file: file, fileContent: fileContent})

          //console.log(acceptedFiles[0].name) // size type
        }
        reader.readAsDataURL(file)
    }

  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className="col">
      <div className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs" id={props.id + '_CONTAINER'}>
        <div className="t-Form-labelContainer">
          <label for={props.id} id={props.id + '_LABEL'}
            className="t-Form-label">{props.label} <small>{props.labelSmall}</small></label>
        </div>
        <div className="t-Form-inputContainer">
          <div className="t-Form-itemWrapper">
            <div {...getRootProps()} style={{ width: '100%' }}>
              <input {...getInputProps()} />
              {isDragActive && <p>Drop the files here ...</p>}
              {!isDragActive && (<div className="dropZone"> {props.file?.file ? props.file.file.name : props.labelDrop} </div>)}
            </div>
          </div>
          <span id={props.id + '_ERROR'} className="a-Form-error">{props.error}</span>
        </div>
      </div>
    </div>)
}

  const handleCanviCicle = (t) => {
    setCicle(t.target.value)
    setFamilia(null)
    setCurs(null)
    setAssignatures([])
    setMaterials([])
  }

  const handleCanviNouAlumne = (t) => {
    setNouAlumne(t.target.value === "1" ? true : false)
    setNPreinscripcio('')
    setCicle(null)
    setFamilia(null)
    setCurs(null)
    setAssignatures([])
    setMaterials([])
  }

  const handleCanviFamilia = (f) => {
    setFamilia(f)
    setCurs(null)
    setAssignatures([])
    setMaterials([])
  }

  const handleCanviCurs = (c) => {
    let fam = families[cicle].families.filter(f => f.id === familia).pop();
    let cur = fam.cursos.filter(fc => fc.id === c).pop();
    setCurs(cur)
    setAssignatures([])
    setMaterials([])
  }

  const handleCanviAssignatures = (a) => {
    setAssignatures(a)
  }

  const handleCanviTorn = (t) => {
    setTorn(t)
  }

  const handleCanviNivellMax= (n) => {
    setNivellMax(n)
  }


  const toggleDadesInscripcioOpen = () => {
    setDadesInscripcioOpen(!dadesInscripcioOpen)
  }

  const toggleDadesQuotaOpen = () => {
    setDadesQuotaOpen(!dadesQuotaOpen)
  }

  const handleDadesEstudis = (noError) => {

    let d = {
     "validat": noError,
    /* DADES ESTUDIS */

      "cicle": cicle,
      "nouAlumne": nouAlumne,
      "nPreinscripcio": nPreinscripcio,
      "titolAccesFile": titolAccesFile,
      "familia": familia,
      "curs": curs,
      "quota": quota,
      "observacionsMatricula": observacionsMatricula,
      "dadesInscripcioOpen": dadesInscripcioOpen,
      "dadesQuotaOpen": dadesQuotaOpen,
      "assignatures": assignatures,
      "torn": torn,
      "repetidor": repetidor,
      "materials": materials,
      "bonificacions": bonificacio,
      "dosTerminis": dosTerminis,
      "justificantPagamentFile": justificantPagamentFile,
      "bonificacionsFile": bonificacioFile,
      "notesCursAnteriorFile": notesCursAnteriorFile,
      "nivellMax": nivellMax,
    }
  
      setDadesEstudis(d)
      props.handleDadesEstudis(d)
    }


  // condicions per mostrar families 
  let mostraFamilies = (cicle && nouAlumne !== undefined) ? true : false

  // condicions per mostrar cicles
  //let mostraSelectCicle = (nouAlumne !== undefined && (nouAlumne === false || (nouAlumne === true && ( nPreinscripcio.length > 0 && titolAccesFile )))) ? true : false

  let potMatricularCicles = (nouAlumne !== undefined && (nouAlumne === false || (nouAlumne === true && ( nPreinscripcio.length > 0 && titolAccesFile )))) ? true : false
  let mostraSelectCicle = (nouAlumne !== undefined ) ? true : false

  const llistatCompletAssignatures = require('../../data/' + config.assignatures)
  let assignaturesCurs = []

  if(curs)
  {
    assignaturesCurs = llistatCompletAssignatures[curs.id]
  }

  return (
            <div className="container">
              <div className="row">
                <div className="col col-12 ">
                  <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">

                    <div className="t-Region-header">
                      <div className="t-Region-headerItems  t-Region-headerItems--controls">
                        <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={() => toggleDadesInscripcioOpen()}>
                          {dadesInscripcioOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                          {!dadesInscripcioOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                        </button>
                      </div>
                      <div className="t-Region-headerItems t-Region-headerItems--title">
                        <h2 className="t-Region-title a-Collapsible-heading">Inscripció</h2>
                      </div>
                      <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
                    </div>

                    <Dropdown open={dadesInscripcioOpen}>
                      <div className="t-Region-bodyWrap">
                        <div className="t-Region-body a-Collapsible-content">

                          <div className="container">
                            <div className="row">
                              <div className="col col-12 ">
                                <div
                                  className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                                  <div className="t-Region-bodyWrap">
                                    <div className="t-Region-body">
                                      <div className="container">
                                        <div className="row">

                                          <FormSelectNouAlumne id="nouAlumne" label={config.textAlumneNouAlCentre} labelSub={config.textAlumneNouAlCentreSub} nouAlumne={nouAlumne} handleChange={handleCanviNouAlumne} />

                                          {nouAlumne && (
                                          <>
                                            <FormInputText id="ninscripcio" col="col-4" name="inscripcio" label="nº de preinscripció*" value={nPreinscripcio} onChange={setNPreinscripcio} size="20" maxLength="20" />

                                            <div className="col col-4">
                                              <DropZone id="titolAccesFile" 
                                              file={titolAccesFile} 
                                              onChange={setTitolAccesFile} 
                                              label="Adjuntar títol pel qual accedeix*" 
                                              labelDrop="adjuntar títol d'accés" 
                                              error={errors.titolAccesFile} 
                                              handleFileError={ (msg) =>handleFileError('titolAccesFile',msg) }  />
                                            </div>

                                      <div className="row">

                                        <div className="col col-4">
                                          &nbsp;
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col col-8">
                                          <div class="t-Form-labelContainer">
                                              <label className="t-Form-label">
                                                <small>
                                                  Cal proporcionar número de preinscripció i adjuntar títol pel qual s'accedeix per poder proseguir amb la matrícula de CFGM i CFGS.
                                                </small>
                                              </label>
                                          </div>
                                        </div>
                                      </div>

                                            </>
                                          )}

                                          <br/>

                                          {mostraSelectCicle && (<FormSelectCicle id="cicle" label="Nivell d'estudis pels que fas la matrícula:" potMatricularCicles={potMatricularCicles} nouAlumne={nouAlumne} cicle={cicle} handleChange={handleCanviCicle} />)}

                                          {mostraFamilies && cicle === "ESO" && (<ESO label="Família" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "BATX" && (<BATX label="Família" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "CFGM" && (<CFGM label="Família professional" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "CFGS" && (<CFGS label="Família professional" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "CE" && (<CE label="Família professional" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "PFI" && (<PFI label="Família professional" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "IFE" && (<IFE label="Família professional" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "FPB" && (<FPB label="Família professional" familia={familia} handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && familia && (<TriaCurs cicle={cicle} 
                                                                                   familia={familia} 
                                                                                   nouAlumne={nouAlumne}
                                                                                   curs={curs} 
                                                                                   cursObligatori={props.dadesPersonals?.cursObligatori} 
                                                                                   label="Tria curs (tria el curs que faràs)" 
                                                                                   handleChange={handleCanviCurs} />)}

                                          {mostraFamilies && familia && curs && (<TriaAssignatures curs={curs} handleChange={handleCanviAssignatures}
                                                                                                    torn={torn} handleCanviTorn={handleCanviTorn} 
                                                                                                    nivellMax={nivellMax} handleCanviNivellMax={handleCanviNivellMax} 
                                                                                                    tornObligatori={props.dadesPersonals?.tornObligatori}
                                                                                                    repetidor={repetidor} setRepetidor={setRepetidor}
                                                                                                    />)}

                                          {mostraFamilies && familia && curs && (<FormTextArea id="observacionsMatricula" name="observacionsMatricula" label="Observacions tria d'assignatures"  obligatori={false} rows="5" cols="80" maxLength="1000" value={observacionsMatricula} onChange={setObservacionsMatricula} error={errors.observacionsMatricula}/>)}

                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </Dropdown>

                  </div>
                </div>
              </div>

      {


        /* s'ha d'haver triat minim una assignatura per mostrar quota */
        /* sense condicions si no es ESO o BATX */
        /* amb condicions de triar totes les modals i optatives si es ESO o BATXILLERAT */
        (assignatures.length > 0
          &&
          (
            ((!curs.id.startsWith("ESO") && !curs.id.startsWith("BATX")))
            ||
            ((curs.id.startsWith("ESO") || curs.id.startsWith("BATX")) && assignatures.length === assignaturesCurs.length))
        )
        && (
                <div className="container">
                  <div className="row">
                    <div className="col col-12 ">
                      <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">

                        <div className="t-Region-header">
                          <div className="t-Region-headerItems  t-Region-headerItems--controls">
                            <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={() => toggleDadesQuotaOpen()}>
                              {dadesQuotaOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                              {!dadesQuotaOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                            </button>
                          </div>
                          <div className="t-Region-headerItems t-Region-headerItems--title">
                            <h2 className="t-Region-title a-Collapsible-heading">Quota d'inscripció</h2>
                          </div>
                          <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
                        </div>


                        <Dropdown open={dadesQuotaOpen}>
                        <div className="t-Region-bodyWrap">
                          <div className="t-Region-body a-Collapsible-content">

                            <div className="container">
                              <div className="row">
                                <div className="col col-12 ">
                                  <MostraQuota curs={curs} 
                                               cicle={cicle}
                                               quota={quota} 
                                               assignatures={assignatures} 
                                               nouAlumne={nouAlumne}

                                               repetidor={repetidor} setRepetidor={setRepetidor} error={errors}
                                               materials={materials} setMaterials={setMaterials} 
                                               bonificacions={bonificacio} setBonificacions={setBonificacio} 
                                               dosTerminis={dosTerminis} setDosTerminis={setDosTerminis} 

                                               nivellMax={nivellMax}

                                               iddocument={iddocument}
                                               becat={becat}
                                               />
                                </div>

                          <div className="row" style={{ paddingTop: '2rem'}}>

                            { /* config.pagamentTPV && ( quota?.total > 0 && (
                              <div className="col col-4">
                                <small> ** pagament TPV - en desenvolupament -- TODO ** </small>
                              </div>))*/}


                            { config.pagamentTransferencia && ( quota?.total > 0 && (
                              <div className="col col-4">
                                <DropZone id="justificantPagamentFile" 
                                file={justificantPagamentFile} 
                                onChange={setJustificantPagamentFile} 
                                label="Adjuntar justificant del pagament*" 
                                labelDrop="adjuntar justificant" 
                                error={errors.justificantPagamentFile} 
                                handleFileError={ (msg) =>handleFileError('justificantPagamentFile',msg) }  />
                              </div>))}

                            {quota?.bonificacio > 0 && (
                              <div className="col col-4">
                                <DropZone id="bonificacionsFile" file={bonificacioFile} onChange={setBonificacioFile} label="Adjuntar justificant del bonificació/exempció*" labelDrop="adjuntar justificant" error={errors.bonificacioFile} 
                                handleFileError={ (msg) =>handleFileError('bonificacionsFile',msg) }  />
                              </div>)}

                            { nouAlumne && ( nivellMax === 2 && (
                              <div className="col col-4">
                                <DropZone id="notesCursAnteriorFile" file={notesCursAnteriorFile} onChange={setNotesCursAnteriorFile} label="Per alumnes nous que vulguin UF de 2n curs cal adjuntar notes curs anterior*" labelDrop="adjuntar notes curs anterior" error={errors.notesCursAnteriorFile} 
                                handleFileError={ (msg) =>handleFileError('notesCursAnteriorFile',msg) }  />
                              </div>))}


                          </div>
                              </div>


                            </div>

                          </div>
                        </div>

                          <div className="container">
                            <div className="row">
                              <div className="col col-12" align="center">
                                <button onClick={validaEstudis} className="button">SEGÜENT</button>
                              </div>
                            </div>
                          </div>

                        </Dropdown>

                      </div>

                    </div>
                  </div>

                </div>)
              }
            </div>


  );
}