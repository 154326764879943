import React from 'react';

export default function EnrolmentFormBreadcrumb(props) {


  let dadesPersonalsStatus = "t-WizardSteps-step"
  let dadesMediquesStatus = "t-WizardSteps-step"
  let dadesLaboralsStatus = "t-WizardSteps-step"
  let dadesEstudisStatus = "t-WizardSteps-step"
  let dadesAutoritzacioStatus = "t-WizardSteps-step"

 if(props.dadesPersonals.validat === false)
 {
  dadesPersonalsStatus += " is-active"
 }

 if(props.dadesPersonals.validat === true)
 {
  dadesPersonalsStatus += " is-complete"
 }

 if(props.dadesPersonals.validat === true)
 {
  dadesPersonalsStatus = "t-WizardSteps-step is-complete"
  dadesMediquesStatus = "t-WizardSteps-step is-active"
 }


 if(props.dadesPersonals.validat === true  
   && 
  props.dadesMediques.validat === true  
   )
 {
  dadesPersonalsStatus = "t-WizardSteps-step is-complete"
  dadesMediquesStatus = "t-WizardSteps-step is-complete"
  dadesLaboralsStatus = "t-WizardSteps-step is-active"
 }


 if(props.dadesPersonals.validat === true  
   && 
  props.dadesMediques.validat === true  
   && 
  props.dadesLaborals.validat === true  
   )
 {
  dadesPersonalsStatus = "t-WizardSteps-step is-complete"
  dadesMediquesStatus = "t-WizardSteps-step is-complete"
  dadesLaboralsStatus = "t-WizardSteps-step is-complete"
  dadesEstudisStatus = "t-WizardSteps-step is-active"
 }


 if(props.dadesPersonals.validat === true  
   && 
  props.dadesMediques.validat === true  
   && 
  props.dadesLaborals.validat === true  
   && 
  props.dadesEstudis.validat === true  
   )
 {
  dadesPersonalsStatus = "t-WizardSteps-step is-complete"
  dadesMediquesStatus = "t-WizardSteps-step is-complete"
  dadesLaboralsStatus = "t-WizardSteps-step is-complete"
  dadesEstudisStatus = "t-WizardSteps-step is-complete"
  dadesAutoritzacioStatus = "t-WizardSteps-step is-active"
 }


 if(props.dadesPersonals.validat === true  
   && 
  props.dadesMediques.validat === true  
   && 
  props.dadesLaborals.validat === true  
   && 
  props.dadesEstudis.validat === true  
   && 
  props.dadesAutoritzacions.validat === true  
   )
 {
  dadesPersonalsStatus = "t-WizardSteps-step is-complete"
  dadesMediquesStatus = "t-WizardSteps-step is-complete"
  dadesLaboralsStatus = "t-WizardSteps-step is-complete"
  dadesEstudisStatus = "t-WizardSteps-step is-complete"
  dadesAutoritzacioStatus = "t-WizardSteps-step is-complete"
 }

 //console.log("PROPS EDAT",props.dadesPersonals)
 if(props.dadesPersonals.edat && props.dadesPersonals.edat <16)
 {
   dadesLaboralsStatus = dadesLaboralsStatus + " no-display"
 }

  return (<div className="row">
    <div className="col col-12 ">
      <div className="t-Wizard t-Wizard--hideStepsXSmall margin-bottom-none">
        <div className="t-Wizard-header">
          <div className="u-Table t-Wizard-controls">
            <div className="u-Table-fill t-Wizard-steps">

              <ul className="t-WizardSteps t-WizardSteps--displayLabels">

                <li className={dadesPersonalsStatus}>
                  <div className="t-WizardSteps-wrap">
                    <span className="t-WizardSteps-marker"></span>
                    <span className="t-WizardSteps-label">
                      Dades personals
                    </span>
                  </div>
                </li>

                <li className={dadesMediquesStatus}>
                  <div className="t-WizardSteps-wrap">
                    <span className="t-WizardSteps-marker">
                    </span>

                    <span className="t-WizardSteps-label">
                      Dades mèdiques
                      <span className="t-WizardSteps-labelState"></span>
                    </span>
                  </div>
                </li>


                <li className={dadesLaboralsStatus}>
                  <div className="t-WizardSteps-wrap">
                    <span className="t-WizardSteps-marker">
                    </span>

                    <span className="t-WizardSteps-label">
                      Dades laborals
                      <span className="t-WizardSteps-labelState"></span>
                    </span>
                  </div>
                </li>

                <li className={dadesEstudisStatus}>
                  <div className="t-WizardSteps-wrap">

                    <span className="t-WizardSteps-marker">
                    </span>

                    <span className="t-WizardSteps-label">
                      Inscripció
                      <span className="t-WizardSteps-labelState"></span>
                    </span>
                  </div>
                </li>


                <li className={dadesAutoritzacioStatus}>
                  <div className="t-WizardSteps-wrap">

                    <span className="t-WizardSteps-marker">
                    </span>

                    <span className="t-WizardSteps-label">
                      Protecció de Dades
                      <span className="t-WizardSteps-labelState"></span>
                    </span>
                  </div>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}