import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'react-slidedown/lib/slidedown.css'
import Dropdown from '../../dropdown'
import { FormSelectYesNo } from '../formelements'
import config from '../../../config'

export function EnrolmentFormAuthorizations(props) {

  const [errors, setErrors] = useState({});

  const handleErrors = (nom, error) => {
    //console.log('error', nom, error)
    setErrors(error)
  }

  /* AUTORITZACIONS */
  const [authorizationsOpen, setAuthorizationsOpen] = useState(true);

  const toggleAuthorizationsOpen = () => {
    setAuthorizationsOpen(!authorizationsOpen);
  }

  const [authIntranet, setAuthIntranet ] = useState(false);
  const [authPublicacions, setAuthPublicacions ] = useState(false);
  const [authMaterial, setAuthMaterial ] = useState(false);
  const [authMaterialInicials, setAuthMaterialInicials ] = useState(false);
  const [authInternet, setAuthInternet ] = useState(false);

  const [authDretsImatge , setAuthDretsImatge ] = useState("1");

  const [authCompromis, setAuthCompromis ] = useState("0");
  const [authGDPR, setAuthGDPR ] = useState("0");
  const [authSortides, setAuthSortides ] = useState("0");
  const [authAMPA, setAuthAMPA ] = useState("0");
  const [authMedica, setAuthMedica] = useState("0");
  const [authParacetamol, setAuthParacetamol ] = useState("0");
  const [authGimnas, setAuthGimnas ] = useState("0");
  const [authReligio, setAuthReligio ] = useState("0");
  const [authAbsentisme, setAuthAbsentisme] = useState("0");
  const [authAlternanca, setAuthAlternanca ] = useState("0");
  const [authResponsable, setAuthResponsable ] = useState("0");

  const [dadesAutoritzacions, setDadesAutoritzacions] = useState({validat: false});

  const handleDadesAutoritzacions = (noError) => {
    let d = {
     "validat": noError,
  
    /* DADES AUTORITZACIONS */
    "authIntranet": authIntranet,
    "authPublicacions": authPublicacions,
    "authMaterial": authMaterial,
    "authMaterialInicials": authMaterialInicials,
    "authInternet": authInternet,
    "authCompromis": authCompromis,
    "authGDPR": authGDPR,
    "authDretsImatge": authDretsImatge,
    "authSortides": authSortides,
    "authAMPA": authAMPA,
    "authMedica": authMedica,
    "authParacetamol": authParacetamol,
    "authGimnas": authGimnas,
    "authReligio": authReligio,
    "authAbsentisme": authAbsentisme,
    "authAlternanca": authAlternanca,
    "authResponsable": authResponsable,
    }
  
      setDadesAutoritzacions(d)
      props.handleDadesAutoritzacions(d)
    }


  const validaAuthIntranet = () => {
    return authIntranet ? null : "Heu d'indicar una resposta"
  }

  const validaAuthPublicacions= () => {
    return authPublicacions ? null : "Heu d'indicar una resposta"
  }

  const validaAuthMaterial= () => {
    return authMaterial ? null : "Heu d'indicar una resposta"
  }

  const validaAuthMaterialInicials = () => {
    return authMaterialInicials ? null : "Heu d'indicar una resposta"
  }

  const validaAuthDretsImatge = () => {
    //només obligatori pels menors de 14
    //if(props.dadesPersonals.edat >= 14 ) return null
    return authDretsImatge ? null : "Heu d'indicar una resposta"
  }

  const validaAuthReligio= () => {
    return authReligio === "0" ? "Heu d'indicar una opció" : null
  }

  const validaAuthInternet= () => {
    return authInternet ? null : "Heu d'indicar una resposta"
  }

  const validaAuthCompromis = () => {
    return authCompromis === "1" ? null : "Aquest camp és obligatori"
  }

  const validaAuthGDPR= () => {
    return authGDPR === "1" ? null : "Aquest camp és obligatori"
  }

  const validaAuthResponsable= () => {
    return authResponsable === "1" ? null : "Aquest camp és obligatori"
  }

 const handleChangeCompromis = (t) => {

      setAuthCompromis(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }

 const handleChangeGDPR = (t) => {

      setAuthGDPR(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }

 const handleChangeResponsable = (t) => {

      setAuthResponsable(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }

 const handleChangeSortides = (t) => {

      setAuthSortides(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }

 const handleChangeAMPA= (t) => {

      setAuthAMPA(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }

 const handleChangeMedica= (t) => {

      setAuthMedica(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }

 const handleChangeParacetamol = (t) => {

      setAuthParacetamol(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }

 const handleChangeGimnas = (t) => {

      setAuthGimnas(t === "0" ? "1" : "0") 

      if(t === "1") handleDadesAutoritzacions(false)
 }


 const handleChangeReligio = (t) => {

    // valor de t: "1" o "2"
     setAuthReligio(t)

     handleDadesAutoritzacions(false)
 }

 const handleChangeAbsentisme = (t) => {

      setAuthAbsentisme(t === "0" ? "1" : "0") 

     handleDadesAutoritzacions(false)
 }

 const handleChangeAlternanca = (t) => {

      setAuthAlternanca(t === "0" ? "1" : "0") 

     handleDadesAutoritzacions(false)
 }




/* VALIDA AUTORITZACIONS */
const validaAutoritzacions = (e) => {
  if(e) e.preventDefault();

  let error = {}

  if (props.dadesPersonals.edat >= 14) {

    //només obligatori pels majors de 14
    error.authIntranet = validaAuthIntranet();
    handleErrors('authIntranet', error)

    error.authPublicacions = validaAuthPublicacions();
    handleErrors('authPublicacions', error)

    error.authMaterial = validaAuthMaterial();
    handleErrors('authMaterial', error)

    error.authMaterialInicials = validaAuthMaterialInicials();
    handleErrors('authMaterialInicials', error)

    error.authInternet = validaAuthInternet();
    handleErrors('authInternet', error)

  } else {

    //només obligatori pels menors de 14
    error.authDretsImatge = validaAuthDretsImatge();
    handleErrors('authDretsImatge', error)

  }

  error.authCompromis = validaAuthCompromis();
  handleErrors('authCompromis',error)

  error.authGDPR = validaAuthGDPR();
  handleErrors('authGDPR',error)

  error.authResponsable = validaAuthResponsable();
  handleErrors('authResponsable',error)


  if(props.dadesEstudis.curs.id.startsWith("ESOLOE"))
  {
    error.authReligio = validaAuthReligio();
    handleErrors('authReligio',error)
  }


  setErrors(error)

  let noError = true;
  //DEVELOP 
  if(!config.develop) // bypass errors if develop == true
  {
    Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
  }

  console.log('ERROR',error,noError);

  handleDadesAutoritzacions(noError)

  if(noError)
  {
     setAuthorizationsOpen(false);
  }

  return noError;
}

  return (
    <div className="container authorizations">
      <div className="row">
        <div className="col col-12 ">
          <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
            <div className="t-Region-header">
              <div className="t-Region-headerItems  t-Region-headerItems--controls">
                <button
                  className="t-Button t-Button--icon t-Button--hideShow"
                  type="button"
                  onClick={toggleAuthorizationsOpen}
                >
                  {authorizationsOpen && (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      fontSize="18"
                      className="t-Icon"
                    />
                  )}
                  {!authorizationsOpen && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      fontSize="18"
                      className="t-Icon"
                    />
                  )}
                </button>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--title">
                <h2 className="t-Region-title a-Collapsible-heading">
                  Protecció de dades
                </h2>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
            </div>
            <div className="t-Region-bodyWrap">
              <Dropdown open={authorizationsOpen}>
                <div className="t-Region-body a-Collapsible-content">
                  <div className="container">
                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body">
                              <div className="container">

                                   { props.dadesPersonals.edat >= 16 ? (
                                     <>
                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Carta de compromís educatiu: Alumnat amb 16 anys complerts. Curs {props.enguany}-{props.anyVinent}
                                    </h4>

                                    <p>Conscients que l’educació dels joves implica l’acció conjunta de la família i de l’institut, signem aquesta carta de compromís educatiu, la qual comporta els següents COMPROMISOS:</p>

                                    <h5>
                                    Per part del centre:
                                    </h5>

                                      <ol>
                                        <li>Proporcionar una formació que contribueixi al desenvolupament integral de la personalitat de l’alumne/a.</li>
                                        <li>Fer respectar els drets de l’alumne/a en l’àmbit escolar.</li>
                                        <li>Educar en la línia del respecte a un mateix, el respecte als altres i el respecte a l’entorn.</li>
                                        <li>Informar la família del projecte educatiu i de les normes d’organització i funcionament del centre.</li>
                                        <li>Informar la família i l’alumne/a dels criteris que s’aplicaran per avaluar el rendiment acadèmic, fer-ne una valoració objectiva i, si escau, explicar a la família els resultats de les avaluacions.</li>
                                        <li>Adoptar les mesures educatives alternatives o complementàries adients, dins les possibilitats del centre, per atendre les necessitats específiques de l’alumne/a i mantenir-ne informada la família.</li>
                                        <li>Mantenir comunicació regular amb la família per informar-la de l’evolució acadèmica personal de l’alumne/a.</li>
                                        <li>Comunicar a la família les absències no justificades de l’alumne/a al centre, i qualsevol altra circumstància que sigui rellevant per al seu desenvolupament acadèmic i personal.</li>
                                        <li>Atendre en un termini raonable les peticions d’entrevista o de comunicació que formuli la família.</li>
                                        <li>Revisar periòdicament l’evolució de l’alumne/a i, si escau, proposar mesures alternatives a la permanència en l’institut o, fins i tot, la seva sortida.</li>
                                        <li>Revisar conjuntament amb la família el compliment d’aquests compromisos i, si escau, el seu contingut.</li>
                                      </ol>

                                    <h5>
                                    Per part de la família:
                                    </h5>

                                      <ol>

                                        <li>Respectar el caràcter propi del centre i reconèixer l’autoritat del professorat i la de l’equip directiu.</li>
                                        <li>Compartir amb el centre l’educació del fill/a i desenvolupar i afavorir les complicitats que són necessàries per aplicar el projecte educatiu del centre.</li>
                                        <li>Instar el fill o filla a respectar les normes específiques de funcionament del centre, en particular, les que afecten a la convivència escolar i el desenvolupament normal de les classes.</li>
                                        <li>Acceptar l’establiment de mesures correctores en cas d’incompliment tal i com figuren a les normes d’organització i funcionament del centre.</li>
                                        <li>Vetllar perquè el fill/a compleixi el deure bàsic de l’estudi i de l’assistència regular i puntual a les activitats acadèmiques, i també perquè faci les tasques encomanades a casa pel professorat.</li>
                                        <li>Ajudar el nostre fill o filla a organitzar el temps d’estudi a casa i a preparar el material per a l’activitat escolar.  També haurem de procurar que tingui sempre el material escolar necessari pel treball a l’Institut.</li>
                                        <li>Adoptar criteris i mesures que puguin afavorir el rendiment escolar del fill o filla.</li>
                                        <li>Adreçar-se directament al centre per contrastar les discrepàncies, coincidències o suggeriments en relació amb l’aplicació del projecte educatiu en la formació del fill o filla.</li>
                                        <li>Facilitar al centre les informacions del fill o filla que siguin rellevants per al procés d’aprenentatge.</li>
                                        <li>Atendre les peticions d’entrevista o de comunicació que formuli el centre.</li>
                                        <li>Signar la baixa del fill/a del centre si, o bé no hi ha evolució en el procés d’ensenyament-aprenentatge del fill/a, o bé aquesta evolució no és positiva.</li>
                                        <li>Informar el fill o filla del contingut d’aquests compromisos.</li>

                                      </ol>


                                    <h5>
                                    Per part de l'alumne:
                                    </h5>

                                      <ol>

                                        <li>Respectar el caràcter propi del centre i reconèixer l’autoritat del professorat i la de l’equip directiu.</li>
                                        <li>Respectar les normes específiques de funcionament del centre, en particular, les que afecten a la convivència escolar i el desenvolupament normal de les classes.</li>
                                        <li>Acceptar l’establiment de mesures correctores en cas d’incompliment tal i com figuren a les normes d’organització i funcionament del centre.</li>
                                        <li>Complir amb el deure bàsic de l’estudi i de l’assistència regular i puntual a les activitats acadèmiques, i fer les tasques encomanades a casa pel professorat.</li>
                                        <li>Tenir sempre el material escolar necessari pel treball a l’Institut.</li>
                                        <li>Atendre les peticions d’entrevista o de comunicació que formuli el tutor/a o qualsevol membre de l’equip de professors.</li>
                                        <li>Signar la baixa si, o bé no hi ha evolució en el procés d’ensenyament-aprenentatge, o bé aquesta evolució no és positiva, o bé no es compleix algun, o alguns, dels compromisos que signa l’alumne/a.</li>

                                      </ol>


                                    <p>
                                      <input type="checkbox" id="authCompromis" name="authCompromis" value={authCompromis} checked={authCompromis === "1" ? true : false } onChange={ (t) => { handleChangeCompromis(t.target.value) } } required/>
                                        Perquè així consti, signem aquesta carta de compromís educatiu.
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authCompromis}</span>
                                    </p>

                                  </div>
                                </div>

                                        </>
                                   ) : (
                                     <>
                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Carta de compromís educatiu: Alumnat menors 16 anys complerts. Curs {props.enguany}-{props.anyVinent}
                                    </h4>

                                    <p>Conscients que l’educació dels joves implica l’acció conjunta de la família i de l’institut, signem aquesta carta de compromís educatiu, la qual comporta els següents COMPROMISOS:</p>

                                    <h5>
                                    Per part del centre:
                                    </h5>

                                      <ol>
                                          <li>Proporcionar una formació que contribueixi al desenvolupament integral de la personalitat de l’alumne/a.</li>
                                          <li>Fer respectar els drets de l’alumne/a en l’àmbit escolar.</li>
                                          <li>Educar en la línia del respecte a un mateix, el respecte als altres i el respecte a l’entorn.</li>
                                          <li>Informar la família del projecte educatiu i de les normes d’organització i funcionament del centre.</li>
                                          <li>Informar la família i l’alumne/a dels criteris que s’aplicaran per avaluar el rendiment acadèmic, fer-ne una valoració objectiva i, si escau, explicar a la família els resultats de les avaluacions.</li>
                                          <li>Adoptar les mesures educatives alternatives o complementàries adients, dins les possibilitats del centre, per atendre les necessitats específiques de l’alumne/a i mantenir-ne informada la família.</li>
                                          <li>Mantenir comunicació regular amb la família per informar-la de l’evolució acadèmica personal de l’alumne/a.</li>
                                          <li>Comunicar a la família les absències no justificades de l’alumne/a al centre, i qualsevol altra circumstància que sigui relevant per al seu desenvolupament acadèmic i personal.</li>
                                          <li>Atendre en un termini raonable les peticions d’entrevista o de comunicació que formuli la família.</li>
                                          <li>Revisar conjuntament amb la família el compliment d’aquests compromisos i, si escau, el seu contingut.</li>
                                      </ol>

                                    <h5>
                                    Per part de la família:
                                    </h5>

                                      <ol>
                                            <li>Respectar el caràcter propi del centre i reconèixer l’autoritat del professorat i la de l’equip directiu.</li>
                                            <li>Compartir amb el centre l’educació del fill/a i desenvolupar i afavorir les complicitats que són necessàries per aplicar el projecte educatiu del centre.</li>
                                            <li>Instar el fill o filla a respectar les normes específiques de funcionament del centre, en particular, les que afecten a la convivència escolar i el desenvolupament normal de les classes.</li>
                                            <li>Acceptar l’establiment de mesures correctores en cas d’incompliment tal i com figuren a les normes d’organització i funcionament del centre.</li>
                                            <li>Vetllar perquè el fill/a compleixi el deure bàsic de l’estudi i de l’assistència regular i puntual a les activitats acadèmiques, i també perquè faci les tasques encomanades a casa pel professorat.</li>
                                            <li>Ajudar el nostre fill o filla a organitzar el temps d’estudi a casa i a preparar el material per a l’activitat escolar. També haurem de procurar que tingui sempre el material escolar necessari pel treball a l’Institut.</li>
                                            <li>Adoptar criteris i mesures que puguin afavorir el rendiment escolar del fill o filla.</li>
                                            <li>Adreçar-se directament al centre per contrastar les discrepàncies, coincidències o suggeriments en relació amb l’aplicació del projecte educatiu en la formació del fill o filla.</li>
                                            <li>Facilitar al centre les informacions del fill o filla que siguin rellevants per al procés d’aprenentatge.</li>
                                            <li>Atendre les peticions d’entrevista o de comunicació que formuli el centre.</li>
                                            <li>Informar el fill o filla del contingut d’aquests compromisos.</li>
                                            <li>Responsabilitzar-se d’assistir a totes les reunions individuals i col·lectives convocades pel centre.</li>
                                            <li>Acceptar el compliment de sancions en un temps extraordinari fora de l’horari lectiu. L’incompliment d’aquest punt pot comportar l’apertura d’un procediment sancionador que pot anar de 3 a 20 dies lectius.</li>
                                            <li>Revisar conjuntament amb el centre educatiu el compliment dels compromisos de la carta.</li>
                                      </ol>


                                    <p>
                                      <input type="checkbox" id="authCompromis" name="authCompromis" value={authCompromis} checked={authCompromis === "1" ? true : false } onChange={ (t) => { handleChangeCompromis(t.target.value) } } required/>
                                        <label htmlFor="authCompromis">Perquè així consti, signem aquesta carta de compromís educatiu.</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authCompromis}</span>
                                    </p>

                                  </div>
                                </div>

                                        </>
                                    )
                                  }

<br/>
<br/>
{ props.dadesPersonals.edat >= 14 ? (
                                      <>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                      Autorització relativa a l’alumnat major de
                                      14 anys i del personal docent i no docent
                                      del centre: ús d’imatges, publicació de
                                      dades de caràcter personal i de material
                                      que elaboren. Curs {props.enguany}-
                                      {props.anyVinent}
                                    </h4>
                                    <p>
                                      El centre disposa d’espais de comunicació
                                      i difusió, inclosos els espais web (adreça
                                      o adreces web), on informa i fa difusió de
                                      les activitats escolars lectives,
                                      complementàries i extraescolars. En
                                      aquests espais es poden publicar
                                      imatges/veu en què aparegui,
                                      individualment o en grup, alumnat i
                                      personal docent que fa les activitats
                                      esmentades. El dret a la pròpia imatge és
                                      reconegut en l’article 18.1 de la
                                      Constitució espanyola i està regulat per
                                      la Llei orgànica 1/1982, de 5 de maig,
                                      sobre el dret a l’honor, a la intimitat
                                      personal i familiar i a la pròpia imatge,
                                      per la qual cosa la direcció d’aquest
                                      centre demana el consentiment per publicar
                                      fotografies i vídeos on surti alumnat i
                                      personal docent que hi sigui clarament
                                      identificable.
                                    </p>

                                    <p>
                                      Autoritzo mentre mantingui vinculació amb
                                      el centre
                                    </p>

                                    <p>
                                      1. Que la meva imatge/veu pugui sortir en
                                      fotografies i/o vídeos corresponents a
                                      activitats escolars lectives,
                                      complementàries i extraescolars
                                      organitzades pel centre i publicades en: -
                                      En webs del centre:
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <FormSelectYesNo
                                      id="authIntranet"
                                      name="authIntranet"
                                      col="col-4"
                                      label="Intranet amb accés restringit:"
                                      obligatori={true}
                                      value={authIntranet}
                                      handleChange={(e) => setAuthIntranet(e.target.value) }
                                      error={errors.authIntranet}
                                    />

                                    <FormSelectYesNo
                                      id="authPublicacions"
                                      name="authPublicacions"
                                      col="col-4"
                                      label="Publicacions del centre"
                                      obligatori={true}
                                      value={authPublicacions}
                                      handleChange={(e) => setAuthPublicacions(e.target.value) } 
                                      error={errors.authPublicacions}
                                    />

                                    <FormSelectYesNo
                                      id="authInternet"
                                      name="authInternet"
                                      col="col-4"
                                      label="Internet amb accés no restringit"
                                      obligatori={true}
                                      value={authInternet}
                                      handleChange={(e) => setAuthInternet(e.target.value) } 
                                      error={errors.authInternet}
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <p>
                                      2. Que el material que elabori pugui ser publicat en els espais de comunicació
                                      (blogs i espais web i revistes) del mateix centre amb la finalitat de desenvolupar
                                      l’activitat educativa:
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">

                                    <FormSelectYesNo
                                      id="authMaterial"
                                      name="authMaterial"
                                      col="col-4"
                                      label="Autoritzo"
                                      obligatori={true}
                                      value={authMaterial}
                                      handleChange={(e) => setAuthMaterial(e.target.value) } 
                                      error={errors.authMaterial}
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <p>
                                    3. Que en els webs o blogs i revistes editades pel centre hi constin les meves inicials i les del centre:
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">

                                    <FormSelectYesNo
                                      id="authMaterialInicials"
                                      name="authMaterialInicials"
                                      col="col-4"
                                      label="Autoritzo"
                                      obligatori={true}
                                      value={authMaterialInicials}
                                      handleChange={(e) => setAuthMaterialInicials(e.target.value) } 
                                      error={errors.authMaterialInicials}
                                    />
                                  </div>
                                </div>
                                </>
                                ) 
                                :
                                (
                                      <>
                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                       Autorització als alumnes menors de 14: Drets d’imatge. Curs {props.enguany}-{props.anyVinent}
                                    </h4>
                                    <p>
                                    El pare, mare, tutor/a legal o alumne/a menor de 14 anys autoritza que l'alumne/a pugui aparèixer en imatges i/o vídeos, 
                                    es publiqui el seu nom, així com material elaborat per l'alumne/a corresponents a activitats escolars lectives, complementàries 
                                    i extraescolars organitzades pel centre, i publicades a la pàgina web del centre, o revistes, o publicacions editades pel 
                                    centre, així com les seves xarxes socials.
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <FormSelectYesNo
                                      id="authDretsImatge"
                                      name="authDretsImatge"
                                      col="col-4"
                                      label="Drets d'Imatge:"
                                      obligatori={true}
                                      value={authDretsImatge}
                                      handleChange={(e) => setAuthDretsImatge(e.target.value) }
                                      error={errors.authDretsImatge}
                                    />
                                </div>
                               </div>
                                </>
                                )
                              }
                                      <br/>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                      Informació bàsica sobre protecció de dades
                                    </h4>
                                    <p>
                                      <b>Responsable del tractament:</b> Direcció del centre educatiu.<br/>
                                      <b>Finalitat:</b> Autorització difusió d’imatge/veu i material elaborat als espais de comunicació i difusió del centre.<br/>
                                      <b>Legitimació:</b> Consentiment de l’interessat o de la persona que ostenta la tutoria legal en cas de menors d’edat.<br/>
                                      <b>Destinataris:</b> Les dades no es comunicaran a tercers, excepte en els casos previstos per llei, o si ho heu consentit
                                      prèviament.<br />
                                      <b>Drets:</b> Accedir a les dades, rectificar-les, suprimir-les, oposar-se al tractament i sol·licitar-ne la limitació.<br />
                                      <b>Informació addicional:</b> Podeu consultar la informació addicional i detallada sobre protecció de dades a la pàgina:
                                    </p>
                                      <ul>
                                        <li><a href="https://educacio.gencat.cat/ca/Detall/alumnes-centres-departament" target="_blank">https://educacio.gencat.cat/ca/Detall/alumnes-centres-departament</a> </li>
                                        <li><a href="https://educacio.gencat.cat/ca/Detall/personal-centres-departament" target="_blank">https://educacio.gencat.cat/ca/Detall/personal-centres-departament</a></li>
                                      </ul>
                                    <br/>



                                    <p>
                                      <input type="checkbox" id="authGDPR" name="authGDPR" value={authGDPR} checked={authGDPR === "1" ? true : false } onChange={ (t) => { handleChangeGDPR(t.target.value) } } required/>
                                      <label htmlFor="authGDPR">He llegit la informació bàsica sobre protecció de dades i autoritzo el tractament de les meves dades personals.</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authGDPR}</span>
                                    </p>

                                  </div>
                                </div>

                                      <br/>

                                { props.dadesPersonals.edat < 18 && (
                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització per a realitzar activitats fora del centre
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authSortides" name="authSortides" value={authSortides} checked={authSortides === "1" ? true : false } onChange={ (t) => { handleChangeSortides(t.target.value) } } required/>
                                      <label htmlFor="authSortides">
                                        AUTORITZO que el meu fill/a es desplaci fora del Centre per realitzar les excursions i activitats que l’institut programi fora del recinte escolar durant tot el curs.
                                          </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authSortides}</span>
                                    </p>

                                  </div>
                                </div>)}

                                      <br/>

                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització mèdica
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authMedica" name="authMedica" value={authMedica} checked={authMedica === "1" ? true : false } onChange={ (t) => { handleChangeMedica(t.target.value) } } required/>
                                      <label htmlFor="authMedica"> AUTORITZO en cas que es produís una urgència mèdica imprevista greu i l’Institut l’hagués d’acompanyar a un centre mèdic, mentre no arriba la família, autoritzo el professor acompanyant a prendre totes les decisions oportunes (proves, anàlisis, transfusions ...). </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authMedica}</span>
                                    </p>


                                  </div>
                                </div>

                                      <br/>

                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització per a l’administració de paracetamol
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authParacetamol" name="authParacetamol" value={authParacetamol} checked={authParacetamol === "1" ? true : false } onChange={ (t) => { handleChangeParacetamol(t.target.value) } } required/>
                                      <label htmlFor="authParacetamol"> AUTORITZO  El centre educatiu a administrar paracetamol al meu fill/a, en cas de presentar febre superior a 38℃ de temperatura corporal mentre s’espera l’arribada d’algun familiar que el pugui portar a casa. </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authParacetamol}</span>
                                    </p>

                                  </div>
                                </div>

                                      <br/>

                                    {/*<div className="row">
                                      <div className="col col-12 ">
                                        <h4>
                                          Autorització per a activitats esportives
                                        </h4>
                                        <p>
                                          Jo, com a responsable legal que soc d’ell/ella, he fet tot el que calia per conèixer el seu estat de salut; per tant, <br/><br/>
                                          <input type="checkbox" id="authGimnas" name="authGimnas" value={authGimnas} checked={authGimnas === "1" ? true : false} onChange={(t) => { handleChangeGimnas(t.target.value) }} required />
                                          <label htmlFor="authGimnas"> AUTORITZO que faci els exercicis de gimnàstica i les activitats esportives pròpies de la seva edat (exempta, si és el cas, les que s’indiquen en el certificat mèdic oficial que us adjunto).  </label>
                                          <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authGimnas}</span>
                                        </p>

                                      </div>
                                    </div>

                                <br />*/ 
                                }


                                {/* AUTORITZACIONS ESPECIFIQUES ESO */}
                                {props.dadesEstudis.curs.id.startsWith("ESOLOE") && (<>

                                    <div className="row">
                                      <div className="col col-12 ">
                                        <h4>
                                          Declaració sobre l'opció per la formació religiosa o els ensenyaments alternatius
                                        </h4>
                                        <p>
                                          La Constitució proclama el dret que us assisteix com a pares per tal que a l'escola els vostres fills rebin
                                          la formació religiosa i moral que estigui d'acord amb les vostres conviccions (art. 27.3).<br/>
                                          Per tal de facilitar-vos l'exercici d'aquest dret fonamental, i d'acord amb la normativa vigent, caldrà que
                                          manifesteu a la direcció del centre si desitgeu que el vostre fill o filla, rebi formació religiosa a l'institut
                                          durant l'etapa d'Educació Secundària Obligatòria.<br/>

                                          Respecte a aquesta qüestió, us recordem el següent:
                                        </p>
                                          <ul>
                                            <li>
                                              El centre té l'obligació de garantir l'oferta de formació religiosa perquè tots els alumnes els
                                              pares del qual hi manifesteu interès puguin rebre aquesta formació.
                                            </li>
                                            <li>
                                              L'opció per la Religió consisteix en 3 crèdits per cicle. Els alumnes que no rebin formació
                                              religiosa en l'educació secundària realitzaran uns ensenyaments alternatius que
                                              consisteixen en 3 crèdits variables per cicle.
                                            </li>
                                          </ul>

                                          <label>
                                            <input type="radio" id="authReligio" name="authReligio" value={authReligio} checked={authReligio === "1" ? true : false} onChange={(t) => { handleChangeReligio("1") }} required /> DESITGEM que el/la nostre/a fill/a rebi FORMACIÓ RELIGIOSA: CATÒLICA
                                            </label>

                                          <br />
                                          <br />

                                          <label> 
                                           <input type="radio" id="authReligio" name="authReligio" value={authReligio} checked={authReligio === "2" ? true : false} onChange={(t) => { handleChangeReligio("2") }} required /> DESITGEM que el/la nostre/a fill/a rebi els ENSENYAMENTS ALTERNATIUS
                                          </label>

                                          <span id="auhtReligio_ERROR" className="a-Form-error">{errors.authReligio}</span>

                                      </div>
                                    </div>

                                    <br />
                                  </>
                                )}

                                {/* AUTORITZACIONS ESPECIFIQUES 3r i 4t ESO */}
                                { (props.dadesEstudis.curs.id === "ESOLOE03" 
                                    || props.dadesEstudis.curs.id === "ESOLOE04"
                                    || props.dadesEstudis.curs.id.startsWith("BATXLOE")
                                    
                                    ) && (<>
                                
                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització de cessió de dades per a l'orientació en situacions d'absentisme i abandonament d'estudis
                                    </h4>
                                    <p>
                                    (Els majors de 14 anys no necessiten l'autorització del pare, mare o representant legal.)<br/>

                                      Per millorar l'orientació educativa de l'alumnat, el Departament d'Educació sol-licita l'autorització per
                                      comunicar determinades dades personals dels alumnes a l'Administració local i a l'Administració
                                      educativa, que ofereixen un servei d'orientació per donar noves oportunitats als estudiants que es
                                      troben en situació d'absentisme o decideixen abandonar els estudis iniciats, per motius diversos.
                                      Les dades que es poden comunicar són les identificatives i de contacte, nacionalitat, sexe, mes i any de
                                      naixement, i de matrícula i nivell d'ensenyament dels alumnes.
                                    </p>

                                      <input type="checkbox" id="authAbsentisme" name="authAbsentisme" value={authAbsentisme} checked={authAbsentisme === "1" ? true : false } onChange={ (t) => { handleChangeAbsentisme(t.target.value) } } required/>
                                      <label htmlFor="authAbsentisme"> AUTORITZO  el Departament d'Educació a comunicar les dades personals indicades a l'Administració local i l'Administració educativa, exclusivament per gestionar els processos d'acompanyament, orientació, suport i assessorament en les situacions d'absentisme o abandonament d'estudis.  </label>
                                      <span id="auhtAbsentisme_ERROR" className="a-Form-error">{errors.authAbsentisme}</span>

                                    <br />
                                    <p>
                                        <i>Aquesta autorització té validesa durant els pròxims 10 anys o fins que l'alumne/a compleixi 24 anys.  Podeu retirar el vostre consentiment en qualsevol moment.</i>
                                    </p>

                                  </div>
                                </div>
                                
                                    <br />
                                </>)
                                }

                                {/* AUTORITZACIONS ESPECIFIQUES CICLES */}
                                { ( props.dadesEstudis.curs.id.startsWith("CFPM") || props.dadesEstudis.curs.id.startsWith("CFPS") ) && (<>
                                
                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4> Compromís i acceptació de les condicions de la formació en alternança </h4>
                                        <h5> Informació per a l’alumne/a </h5>
                                        <ol>
                                          <li>El centre docent ofereix el cicle formatiu en la modalitat d’alternança indicada, d’acord amb la Resolució 2085/2020, de 20 d’agost,per la qual s'actualitza l'organització de la formació en alternança en els ensenyaments de formació professional inicial.</li>
                                          <li>La formació en alternança dual permet fer una estada remunerada en una empresa, a través de la qual poden ser avaluades ireconegudes acadèmicament, els aprenentatges assolits.</li>
                                          <li>Aquesta formació comporta compatibilitzar les classes al centre educatiu amb l’estada a l’empresa a través d’una beca o d’un contracte laboral.</li>
                                          <li>La convalidació d’unitats formatives, crèdits o mòduls professionals del cicle formatiu en cap cas implica una reducció de les hores de l’estada en l’empresa.</li>
                                          <li>Si, per circumstàncies imprevistes, les empreses participants no poden complir els acords subscrits amb la titularitat del centre,aquestes queden eximides de qualsevol responsabilitat.</li>
                                        </ol>

                                        <h5> Declaració de l’alumne/a </h5>
                                        Declaro que estic informat:
                                        <ul>
                                          <li>de les característiques de desenvolupament de la formació en alternança;</li>
                                          <li>que la matriculació en la modalitat de formació en alternança no garanteix la realització de l’estada formativa en l’empresa amb beca d’empresa o contracte laboral.</li>
                                          <li>que el centre educatiu pot condicionar la proposta de la meva incorporació i continuïtat a l’entitat en funció del meu rendimentacadèmic o de la meva actitud;</li>
                                          <li>que l’entitat pot interrompre l’estada formativa, amb comunicació prèvia a les persones responsables del centre educatiu i d’acord ambla normativa vigent.</li>
                                        </ul>
                                      <p>
                                        <input type="checkbox" id="authAlternanca" name="authAlternanca" value={authAlternanca} checked={authAlternanca === "1" ? true : false} onChange={(t) => { handleChangeAlternanca(t.target.value) }} required />
                                        <label htmlFor="authAlternanca"> AUTORITZO  al centre educatiu i l’entitat formadora perquè es comuniquin mútuament les dades personals necessàries per tramitar i gestionar l’acord formatiu.  </label>
                                        <span id="auhtAlternanca_ERROR" className="a-Form-error">{errors.authAlternanca}</span>
                                      </p>
                                  </div>
                                </div>
                                
                                    <br />
                                </>)
                                }


                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització cessió del correu electrònic a l’AMPA
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authAMPA" name="authAMPA" value={authAMPA} checked={authAMPA === "1" ? true : false } onChange={ (t) => { handleChangeAMPA(t.target.value) } } required/>
                                      <label htmlFor="authAMPA"> AUTORITZO que el centre faliciti el meu correu electrònic a l’AMPA per poder rebre informació </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authAMPA}</span>
                                    </p>

                                  </div>
                                </div>

                                      <br/>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                      Declaració responsable de veracitat de les dades aportades 
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authResponsable" name="authResponsable" value={authResponsable} checked={authResponsable === "1" ? true : false } onChange={ (t) => { handleChangeResponsable(t.target.value) } } required/>
                                      <label htmlFor="authResponsable">Declaro que totes les dades aportades en aquest procediment són certes i la possibilitat d’acreditar documentalment totes les dades esmentades anteriorment, en cas que se m’exigeixin.</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authResponsable}</span>
                                    </p>

                                  </div>
                                </div>



                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                            <div className="row">
                              <div className="col col-12" align="center">
                                <button onClick={validaAutoritzacions} className="button">SEGÜENT</button>
                              </div>
                            </div>
                          </div>

                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}