import React from 'react';
import config from '../../config';

 export default function EnrolmentFormHeader()
 {

    return (
      <header>

        <div className="container">
          <div className="row">
            <div className="col col-12 " style={{ backgroundColor: '#333', padding: '2px 0 0 15px' }}>
              <a href={config.headerURL} className="t-Header-logo-link">
                <img src={config.headerLogo} alt={'Logo ' + config.headerTitle } style={{ margin: '0px' }} /></a>
            </div>
          </div>
        </div>

      </header>
    );
}