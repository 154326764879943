//import React from 'react'
//import dadesbancaries from '../data/dadesbancaries.json'
//import assignatures from '../data/assignatures.json'
//import llista_bonificacions from '../data/llista_bonificacions.json'
import config from '../../../config';
import { calculQuota as calculQuotaCastellarnau, MostraQuota as MostraQuotaCastellarnau } from './quotes.castellarnau';
import { calculQuota as calculQuotaJoanOro, MostraQuota as MostraQuotaJoanOro } from './quotes.joanoro';
import { calculQuota as calculQuotaCavallBernat, MostraQuota as MostraQuotaCavallBernat } from './quotes.cavallbernat';

//const dadesbancaries = require('../data/' + config.dadesbancaries)
//const assignatures = require('../data/' + config.assignatures)

export function calculQuota(curs, assignaturesTriades, dataNaixement, repetidor, setRepetidor, materials, setMaterials, setNivellMax, bonificacio, torn, iddocument, becat )
{
  switch(config.cid)
  {
     // Joan Oro
   case 'a8037152': 
   return calculQuotaJoanOro(curs, assignaturesTriades, dataNaixement, repetidor, setRepetidor, materials, setMaterials, setNivellMax, bonificacio, torn, iddocument, becat );
   
   // Castellarnau
   case 'a8024893': 
   return calculQuotaCastellarnau(curs, assignaturesTriades, dataNaixement, repetidor, setRepetidor, materials, setMaterials, setNivellMax, bonificacio, torn, iddocument, becat );

     // Cavall Bernat
   case 'a8053251': 
   return calculQuotaCavallBernat(curs, assignaturesTriades, dataNaixement, repetidor, setRepetidor, materials, setMaterials, setNivellMax, bonificacio, torn, iddocument, becat );

   default: //error
  }
}

export function MostraQuota(props)
{

  switch(config.cid)
  {
     // Joan Oro
   case 'a8037152': 
    return MostraQuotaJoanOro(props)

     // Castellarnau
   case 'a8024893': 
    return MostraQuotaCastellarnau(props)

     // Cavall Bernat
   case 'a8053251': 
    return MostraQuotaCavallBernat(props)

   default: //error
  }
}