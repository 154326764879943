// config.cavallbernat.js

const textInscripcioText = () =>  {

  return (<>CONSULTEU LA <a href="http://www.inscavallbernat.cat/" target="_blank" rel="noreferrer">PÀGINA WEB DE L'INSTITUT CAVALL BERNAT</a> PER INFORMAR-VOS DEL MATERIAL I ELS LLIBRES QUE NECESSITEU PER INICIAR EL CURS</>)

}

const config =
{
    cid: 'a8053251',
    phone: "93 785 11 43",
    email: "a8053251@xtec.cat",

    headerLogo:  'img/logo_bn_cavallbernat.png',
    headerTitle:  'Institut Cavall Bernat',
    headerURL: 'https://matricula.inscavallbernat.cat/',

    //enrollmentPostURL: 'https://matricula.inscavallbernat.cat/post/',
    enrollmentPostURL: '/post/',
    //apiURL: 'https://matricula.inscavallbernat.cat/api/',
    apiURL: '/api/',

    validaIDdocuments: true,
    tipusDocTSI: true,

    fotoCarnet: true,

    contacteEmergenciaObligatori: true,

    adjuntMedic: true,
    adjuntMedicObligatori: false,

    //datesNoBloquejenInscripcio: false,
    datesNoBloquejenInscripcio: true,

    mostraTorns: false,

    logo: 'img/logo_cavallbernat.jpg',
    titol: 'INS Cavall Bernat',

    favicon: '/img/favicon-cavallbernat.ico',
    faviconGestio: '/gestio/img/favicon-cavallbernat.ico',

    assignatures: 'assignatures.cavallbernat.json',
    families: 'families.cavallbernat.json',
    dadesbancaries: 'dadesbancaries.cavallbernat.json',
    calculquota: 'quotes.cavallbernat.json',

    /* camps especifics per centre */
    treballantFile: true,
    necessitatsEducativesEspecials: true,

    repetidor: false,
    repetidorsNoPodenAgafarAssignaturesDe2n: false,

    textDadesMediquesAlgunSi: "",

    textBotoMatricular: "INSCRIURE",

    textAlumneNouAlCentre: "Ets alumne nou als estudis que et matricules?",
    textAlumneNouAlCentreSub: null,

    urlTPV: "https://matricula.inscavallbernat.cat/tpv/",
    pagamentTPV: true,
    pagamentTransferencia: false,

    textInscripcioText,

    sexeNoBinari: true,
  };

export default  config;