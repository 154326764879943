import React, { useState } from 'react';
//import dadesbancaries from '../data/dadesbancaries.json'
//import assignatures from '../data/assignatures.json'
import llista_bonificacions from '../../../data/llista_bonificacions.json'
import config from '../../../config';

const dadesbancaries = require('../../../data/' + config.dadesbancaries)
const assignatures = require('../../../data/' + config.assignatures)

export function calculQuota(curs, assignaturesTriades, dataNaixement, repetidor, setRepetidor, materials, setMaterials, setNivellMax, bonificacio, torn, iddocument, becat ) {

let quota = {
             matricula: 0,
             asseguranca: 0,
             material: 0,
             formacio: 0,
             bonificacio: 0,
             moduls: 0,
             total: 0,
             dadesbancaries: dadesbancaries }

/* controls que impedeixen que es calcul la quota sense tenir les dades corresponents necessaries per al càlcul */
if(dataNaixement === undefined) return quota
if(curs === undefined) return quota
if(curs?.id === undefined) return quota

// busquem nivell maxim de assignatura (només cal que en tinguin una de 2n per ser matricula de 2n curs)
let nivellMax = 0
const llistaAssignatures = assignatures[curs.id]
  llistaAssignatures.map((ll) => {
    ll.subjects.map((s) => { 

      if(assignaturesTriades.includes(s.id))
      {
          if(s.level > nivellMax)
          {
              nivellMax = s.level
          }
        }
    }
    )
  })

  setNivellMax(nivellMax)

  //if(nivellMax === 2 && !props.nouAlumne ) setRepetidor(2)



let temps= new Date()
temps.setFullYear(new Date().getFullYear() - 28)

  quota.menor28 = dataNaixement.getTime() >= temps.getTime() ? true : false

  const quotaAsseguranca = (menor28) => {

    if (menor28) {
      return 4
    }
      
    return 17.88 + 4
  }

  const quotaMaterial = (curs) => {

    if (curs.material === undefined ) return 0

    if(materials.length === 0 )  // inicial
    {
      let mats = []

      curs.material.map((m) => 
      { 
            return mats.push({...m,checked: m.check })
      })

      //console.log("MATS",mats)

      setMaterials(mats)
    }


    let totalMaterials =  0

      materials.map((m) => 
      { 

          if(m.checked) totalMaterials += m.preu
            
      })

      
    return totalMaterials
  }


  const quotaBonificacio = (matricula,bonificacio) => {

    const bon = llista_bonificacions.filter(b => b.id === bonificacio ).pop()

    if (bon) {

      switch (bon.descompte) {
        case '50%': bonificacio = matricula / 2; break;
        case '100%': bonificacio = matricula; break;
        default: bonificacio = 0;
      }

    }

    return bonificacio
  }


const quotaIFE = (quota,curs,preu) => {

          // assegurança
          quota.asseguranca = quotaAsseguranca(quota.menor28)
          quota.total += quota.asseguranca

          quota.matricula = preu;
          quota.total += quota.matricula

          // material
          quota.material = quotaMaterial(curs)
          quota.total += quota.material

          // total
          return quota;
}



const quotaCFGM = (quota, assignaturesTriades, preuModuls1, preuModuls2) => {

const preuFCT = 10

  // assegurança + despeses
  quota.asseguranca = quotaAsseguranca(quota.menor28) 
  quota.total += quota.asseguranca

  if (assignaturesTriades.length === 1 && /^FCT.2/.test(assignaturesTriades[0])) {

    quota.moduls = preuFCT;

  } else {

    const llistaAssignaturesPrimer = []
    const llistaAssignaturesSegon = []
    const llistaBlocsPrimer = []
    const llistaBlocsSegon = []

    assignatures[curs.id].map((bloc) => {
      //console.log(bloc)

      bloc.subjects.map(
        (assignatura) => {

          //console.log("BL L",assignatura.id,assignaturesTriades,assignaturesTriades.includes(assignatura.id));

          if (assignatura.level === 1) {

            llistaAssignaturesPrimer.push(assignatura.id)

            // les FCT no compten com a modul
            if (!/^FCT/.test(assignatura.id)) {
              if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsPrimer[bloc.id] = llistaBlocsPrimer[bloc.id] == undefined ? 1 : llistaBlocsPrimer[bloc.id] + 1 }
            }

          }

          if (assignatura.level === 2) {

            llistaAssignaturesSegon.push(assignatura.id)

            if (!/^FCT/.test(assignatura.id)) {
              if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsSegon[bloc.id] = llistaBlocsSegon[bloc.id] == undefined ? 1 : llistaBlocsSegon[bloc.id] + 1 }
            }
          }

        }
      )
    }
    )

    //console.log("Blocs",Object.keys(llistaBlocs).length,llistaBlocs)
    //console.log(llistaAssignaturesPrimer)

    matricula_parcial_1r = true;
    //matricula_parcial_1r_count = 0;

    if (llistaAssignaturesPrimer.length > 0) {
      let copiaAssignatures1r = [...llistaAssignaturesPrimer]

      matricula_parcial_1r = copiaAssignatures1r.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_1r_count = copiaAssignatures1r.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    matricula_parcial_2n = true;
    //matricula_parcial_2n_count = 0;

    if (llistaAssignaturesSegon.length > 0) {
      let copiaAssignatures2n = [...llistaAssignaturesSegon]

      matricula_parcial_2n = copiaAssignatures2n.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_2n_count = copiaAssignatures2n.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    //quota.matricula = 0

    /* moduls */
    quota.moduls = 0

    if (matricula_parcial_1r && matricula_parcial_2n) {
      // Les FCT de primer no computen com a assignatura a pagar pero les de segon en matricula parcial si
      quota.moduls = ( preuFCT * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)
    }

    //console.log("PARCIAL 1",matricula_parcial_1r)
    //console.log("PARCIAL 2",matricula_parcial_2n)

    // preu 1r
    if (matricula_parcial_1r) {
      quota.moduls += preuModuls1 * Object.keys(llistaBlocsPrimer).length
    }

    // preu 2n
    if (matricula_parcial_2n) {
      quota.moduls += preuModuls2 * Object.keys(llistaBlocsSegon).length
    }


  }

  quota.total += quota.moduls + quota.matricula


  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {
    //nomes FCT no paguen formacio ni tampoc tenen material
    quota.material = 0
  }
  else {
    quota.material = quotaMaterial(curs)
  }
  quota.total += quota.material

  // total
  return quota;
}


// quota CFGS nou pla d'estudis
const quotaCFGS = (quota, assignaturesTriades, formacio, preuModuls1, preuModuls2) => {

  const preuFCT = 25
  const preuFCTModul = 10
  const preuMatricula = 360
  const preuUF = 65

  //formacio = formacio === undefined ? 60 : formacio

  // assegurança
  quota.asseguranca = quotaAsseguranca(quota.menor28)
  quota.total += quota.asseguranca

  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {

    quota.matricula = preuFCT 
    quota.moduls = preuFCTModul

  } else {

    const llistaAssignaturesPrimer = []
    const llistaAssignaturesSegon = []
    const llistaBlocsPrimer = []
    const llistaBlocsSegon = []

    assignatures[curs.id].map((bloc) => {
      //console.log(bloc)

      bloc.subjects.map(
        (assignatura) => {

          //console.log("BL L",assignatura.id,assignaturesTriades,assignaturesTriades.includes(assignatura.id));

          if (assignatura.level === 1 && !/^FCT/.test(assignatura.id)) {
            llistaAssignaturesPrimer.push(assignatura.id)
            if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsPrimer[bloc.id] = llistaBlocsPrimer[bloc.id] == undefined ? 1 : llistaBlocsPrimer[bloc.id] + 1 }
          }
          if (assignatura.level === 2 && !/^FCT/.test(assignatura.id)) {
            llistaAssignaturesSegon.push(assignatura.id)
            if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsSegon[bloc.id] = llistaBlocsSegon[bloc.id] == undefined ? 1 : llistaBlocsSegon[bloc.id] + 1 }
          }
        }
      )
    }
    )

    //console.log("Blocs",Object.keys(llistaBlocs).length,llistaBlocs)
    //console.log(llistaAssignaturesPrimer)

    matricula_parcial_1r = true;
    //matricula_parcial_1r_count = 0;

    if (llistaAssignaturesPrimer.length > 0) {
      let copiaAssignatures1r = [...llistaAssignaturesPrimer]

      matricula_parcial_1r = copiaAssignatures1r.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_1r_count = copiaAssignatures1r.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    matricula_parcial_2n = true;
    //matricula_parcial_2n_count = 0;

    if (llistaAssignaturesSegon.length > 0) {
      let copiaAssignatures2n = [...llistaAssignaturesSegon]

      matricula_parcial_2n = copiaAssignatures2n.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_2n_count = copiaAssignatures2n.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    //console.log("PARCIAL 1",matricula_parcial_1r,matricula_parcial_1r_count)
    //console.log("PARCIAL 2",matricula_parcial_2n,matricula_parcial_2n_count)


    if (matricula_parcial_1r && matricula_parcial_2n) {
      // Les FCT de primer no computen com a assignatura a pagar 
      quota.matricula = (!matricula_parcial_1r || !matricula_parcial_2n) ? 
                                  preuMatricula 
                                  : 
                                  (preuUF * assignaturesTriades.filter((ass) => !/^FCT/.test(ass)).length) + ( preuFCT * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)

    }
    else {
      quota.matricula = preuMatricula
    }

    if (quota.matricula > preuMatricula) quota.matricula = preuMatricula

    /* moduls */
    quota.moduls = 0

    if (matricula_parcial_1r) {
      quota.moduls += preuModuls1 * Object.keys(llistaBlocsPrimer).length
    }

    if (matricula_parcial_2n) {
      quota.moduls += preuModuls2 * Object.keys(llistaBlocsSegon).length + ( preuFCTModul * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)
    }

  }


  // bonificacio
  if (curs.bonificacions === true) {
    quota.bonificacio = quotaBonificacio(quota.matricula, bonificacio)
  }

  // sumen al total despres de bonificacions
  quota.total += (quota.matricula - quota.bonificacio + quota.moduls)


  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {
    //nomes FCT no paguen formacio ni tampoc tenen material

    //despeses de formació
    quota.formacio = 0
    quota.total += quota.formacio

    quota.material = 0
    quota.total += quota.material
  }
  else {
    //despeses de formació
    quota.formacio = formacio
    quota.total += quota.formacio

    quota.material = quotaMaterial(curs)

    quota.total += quota.material
  }

  return quota;
}

const quotaCFGSA = (quota, assignaturesTriades, formacio, preuModuls1, preuModuls2) => {

  const preuFCT = 25
  const preuFCTModul = 10
  const preuMatricula = 360
  const preuUF = 25

  //formacio = formacio === undefined ? 60 : formacio

  // assegurança
  quota.asseguranca = quotaAsseguranca(quota.menor28)
  quota.total += quota.asseguranca

  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {

    quota.matricula = preuFCT 
    quota.moduls = preuFCTModul

  } else {

    const llistaAssignaturesPrimer = []
    const llistaAssignaturesSegon = []
    const llistaBlocsPrimer = []
    const llistaBlocsSegon = []

    assignatures[curs.id].map((bloc) => {
      //console.log(bloc)

      bloc.subjects.map(
        (assignatura) => {

          //console.log("BL L",assignatura.id,assignaturesTriades,assignaturesTriades.includes(assignatura.id));

          if (assignatura.level === 1 && !/^FCT/.test(assignatura.id)) {
            llistaAssignaturesPrimer.push(assignatura.id)
            if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsPrimer[bloc.id] = llistaBlocsPrimer[bloc.id] == undefined ? 1 : llistaBlocsPrimer[bloc.id] + 1 }
          }
          if (assignatura.level === 2 && !/^FCT/.test(assignatura.id)) {
            llistaAssignaturesSegon.push(assignatura.id)
            if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsSegon[bloc.id] = llistaBlocsSegon[bloc.id] == undefined ? 1 : llistaBlocsSegon[bloc.id] + 1 }
          }
        }
      )
    }
    )

    //console.log("Blocs",Object.keys(llistaBlocs).length,llistaBlocs)
    //console.log(llistaAssignaturesPrimer)

    matricula_parcial_1r = true;
    //matricula_parcial_1r_count = 0;

    if (llistaAssignaturesPrimer.length > 0) {
      let copiaAssignatures1r = [...llistaAssignaturesPrimer]

      matricula_parcial_1r = copiaAssignatures1r.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_1r_count = copiaAssignatures1r.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    matricula_parcial_2n = true;
    //matricula_parcial_2n_count = 0;

    if (llistaAssignaturesSegon.length > 0) {
      let copiaAssignatures2n = [...llistaAssignaturesSegon]

      matricula_parcial_2n = copiaAssignatures2n.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_2n_count = copiaAssignatures2n.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    //console.log("PARCIAL 1",matricula_parcial_1r,matricula_parcial_1r_count)
    //console.log("PARCIAL 2",matricula_parcial_2n,matricula_parcial_2n_count)


    if (matricula_parcial_1r && matricula_parcial_2n) {
      // Les FCT de primer no computen com a assignatura a pagar 
      quota.matricula = (!matricula_parcial_1r || !matricula_parcial_2n) ? 
                                  preuMatricula 
                                  : 
                                  (preuUF * assignaturesTriades.filter((ass) => !/^FCT/.test(ass)).length) + ( preuFCT * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)

    }
    else {
      quota.matricula = preuMatricula
    }

    if (quota.matricula > preuMatricula) quota.matricula = preuMatricula

    /* moduls */
    quota.moduls = 0

    if (matricula_parcial_1r) {
      quota.moduls += preuModuls1 * Object.keys(llistaBlocsPrimer).length
    }

    if (matricula_parcial_2n) {
      quota.moduls += preuModuls2 * Object.keys(llistaBlocsSegon).length + ( preuFCTModul * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)
    }

  }


  // bonificacio
  if (curs.bonificacions === true) {
    quota.bonificacio = quotaBonificacio(quota.matricula, bonificacio)
  }

  // sumen al total despres de bonificacions
  quota.total += (quota.matricula - quota.bonificacio + quota.moduls)


  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {
    //nomes FCT no paguen formacio ni tampoc tenen material

    //despeses de formació
    quota.formacio = 0
    quota.total += quota.formacio

    quota.material = 0
    quota.total += quota.material
  }
  else {
    //despeses de formació
    quota.formacio = formacio
    quota.total += quota.formacio

    quota.material = quotaMaterial(curs)

    quota.total += quota.material
  }

  return quota;
}

// iniciem a 'null'
let formacio = undefined
let llistaAssignaturesPrimer =  []
let llistaAssignaturesSegon =  []
let matricula_parcial_1r = true;
let matricula_parcial_2n = true;
//let matricula_parcial_1r_count = 0;
//let matricula_parcial_2n_count = 0;

quota.asseguranca = 0;
quota.material = 0;
quota.total = 0;


//console.log("CURS",curs.id)

switch(curs.id)
{
  /* JOAN ORO
  { "id": "ESOLOE01", "value": "1r ESO", "condicions": 0, "torns": [ ["M"] , ["M"]]  },
  { "id": "ESOLOE02", "value": "2r ESO", "condicions": 0, "torns": [ ["M"] , ["M"]]  },
  { "id": "ESOLOE03", "value": "3r ESO", "condicions": 0, "torns": [ ["M"] , ["M"]]  },
  { "id": "ESOLOE04", "value": "4t ESO", "condicions": 0, "torns": [ ["M"] , ["M"]]  }
  { "id": "BATXLOE2000", "value": "Batxillerat de ciències i tecnologia", "condicions": 0, "torns": [ ["M"] , ["M"]]  },
  { "id": "BATXLOE3000", "value": "Batxillerat d'humanitats i ciències socials", "condicions": 0, "torns": [ ["M"] , ["M"]]  }
  */

     // ESO 1 i 2
     case "ESOLOE01": 
     case "ESOLOE02": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca

                        quota.matricula = 0;

                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;

     // ESO 3 i 4
     case "ESOLOE03": 
     case "ESOLOE04": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca

                        quota.matricula = 0;

                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;

     // BATX 1 i 2
     case "BATXLOE2000": 
     case "BATXLOE20001": 
     case "BATXLOE20002": 
     case "BATXLOE3000": 
     case "BATXLOE30001": 
     case "BATXLOE30002": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca

                        quota.matricula = 0;

                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;


  /*
  { "id": "IFEAR01", "value": "Auxiliar de cura d'animals i espais verds", "condicions": 0, "torns": [ ["M"] , ["M"]] }
  { "id": "CFPBCM10", "value": "Serveis comercials", "condicions": 0, "torns": [ ["M"] , ["M"]] }
  */

     // IFE Auxiliar de cura d'animals i espais verds
     case "IFEAR0101": 
                       //return quotaIFE(quota,curs,94);
     case "IFEAR0102": 
                       //return quotaIFE(quota,curs,114);
     case "IFEAR0103": 
                       //return quotaIFE(quota,curs,134);
     case "IFEAR0104": 
                       //return quotaIFE(quota,curs,94);
                       return quotaIFE(quota,curs,0);

     // FP BASICA
     case "CFPBCM10": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca

                        quota.matricula = 0;
                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;



   /*
  { "id": "PFIFM03", "value": "Auxiliar de fabricació mecànica i instal·lacions electrotècniques", "condicions": 0, "torns": [ ["M"] ]  }
  { "id": "PFICM01", "value": "Auxiliar de vendes, oficina i atenció al públic", "condicions": 0, "torns": [ ["M"] ]  }
  */

     // PFI
     case "PFIFM03": 
     case "PFICM01": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.total += quota.asseguranca

                        quota.matricula = 0;
                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;

  /*
  { "id": "CFPM1601", "value": "CFGM de Cures auxiliars d’infermeria", "condicions": 0, "torns": [ ["M","T"] ] },
  { "id": "CFPMQU20", "value": "CFGM Operacions de laboratori", "condicions": 0, "torns": [ ["M"] , ["M"]]  }
  { "id": "CFPMTM20", "value": "CFGM de Carrosseria", "condicions": 0, "torns": [ ["M","T"] , ["M","T"]]  },
  { "id": "CFPMTM10", "value": "CFGM d’Electromecànica de Vehicles", "material": [ { "id": "Electude", "preu": 45 } ], "condicions": 0 , "torns": [ ["M","T"] , ["M","T"]]  }
  { "id": "CFPMCM10", "value": "CFGM Activitats Comercials", "condicions": 0, "torns": [ ["M"] , ["M"]]  },
  { "id": "CFPMAG10", "value": "CFGM Gestió Administrativa", "condicions": 0, "torns": [ ["M"] , ["M"]]  }
  { "id": "CFPSSAD0", "value": "CFGS Documentació i Administració Sanitària", "condicions": 0, "torns": [ ["M","T"] , ["M","T"] ] },
  { "id": "CFPSQUD0", "value": "CFGS Laboratori d'anàlisi i control de qualitat", "condicions": 0, "torns": [ ["T"] , ["T"]  ] }
  { "id": "CFPSTMA0", "value": "CFGS Automoció", "material": [ { "id": "Electude", "preu": 45 } ], "condicions": 0 , "torns": [ ["T"] , ["T"]]  }
  { "id": "CFPSCMD0", "value": "CFGS Màrqueting i Publicitat", "condicions": 0, "torns": [ ["T"] , ["T"]]  },
  { "id": "CFPSCMB0", "value": "CFGS Comerç internacional", "condicions": 0, "torns": [ ["T"] , ["T"]]  }
  */

     //CFGM de Cures auxiliars d’infermeria
  case "CFPM1601": 
  case "CFPM1601A": 
    return quotaCFGM(quota, assignaturesTriades, 7.5, 7.5)

  //CFGM Operacions de laboratori 
  case "CFPMQU20": 
  case "CFPMQU20A": 
    //2023 return quotaCFGM(quota, assignaturesTriades, 11, 13)
    return quotaCFGM(quota, assignaturesTriades, 18, 18)

  //CFGM de Carrosseria
  case "CFPMTM20":
  case "CFPMTM20A":
    //2023 return quotaCFGM(quota, assignaturesTriades, 43, 36)
    return quotaCFGM(quota, assignaturesTriades, 43, 45)

  //CFGM d’Electromecànica de Vehicles Automòbils
  case "CFPMTM10":
  case "CFPMTM10A":
    //2023 return quotaCFGM(quota, assignaturesTriades, 38, 35)
    return quotaCFGM(quota, assignaturesTriades, 38, 45)

  //CFGM Perruqueria i Cosmètica capil·lar
  case "CFPMIP10": 
  case "CFPMIP10A": 
    return quotaCFGM(quota, assignaturesTriades, 15, 15)

  //CFGM Gestió Administrativa // TODO ????
  case "CFPMAG10": 
  case "CFPMAG10A": 
    //2023 return quotaCFGM(quota, assignaturesTriades, 9, 9)
    return quotaCFGM(quota, assignaturesTriades, 10, 10)

  // CFGM Activitats Comercials 
  case "CFPMCM10":
  case "CFPMCM10A":
    //2023 return quotaCFGM(quota, assignaturesTriades, 9, 9)
    return quotaCFGM(quota, assignaturesTriades, 10, 10)

  //CFGS Documentació i Administració Sanitària 
  case "CFPSSAD0":
    return quotaCFGS(quota, assignaturesTriades, 0, 15, 15)
  case "CFPSSAD0A":
    //2023 return quotaCFGS(quota, assignaturesTriades, 0, 12, 12)
    return quotaCFGSA(quota, assignaturesTriades, 0, 15, 15)

  // CFGS Laboratori d'anàlisi i control de qualitat
  case "CFPSQUD0":
    return quotaCFGS(quota, assignaturesTriades, 0, 20, 25)
  case "CFPSQUD0A":
    //2023 return quotaCFGS(quota, assignaturesTriades, 0, 19, 19)
    return quotaCFGSA(quota, assignaturesTriades, 0, 20, 25)

  //￼CFGS Automoció
  case "CFPSTMA0":
    return quotaCFGS(quota, assignaturesTriades, 0, 40, 45)
  case "CFPSTMA0A":
    // 2023 return quotaCFGS(quota, assignaturesTriades, 0, 26, 26)
    return quotaCFGSA(quota, assignaturesTriades, 0, 40, 45)

  // CFGS Màrqueting i Publicitat
  case "CFPSCMD0":
    return quotaCFGS(quota, assignaturesTriades, 0, 15, 15)
  case "CFPSCMD0A":
    //2023 return quotaCFGS(quota, assignaturesTriades, 0, 12, 12)
    return quotaCFGSA(quota, assignaturesTriades, 0, 15, 15)

        // CFGS Comerç internacional"
  case "CFPSCMB0":
    return quotaCFGS(quota, assignaturesTriades, 0, 15, 15)
  case "CFPSCMB0A":
    //2023 return quotaCFGS(quota, assignaturesTriades, 0, 12, 12)
    return quotaCFGSA(quota, assignaturesTriades, 0, 15, 15)

  default: return quota;
      
}

}


/*****************************************
 * 
 * MostraQuota
 * 
 * 
 * 
 */

export const MostraQuota = (props)  => {

let quota = props.quota
const [bonificacio, setBonificacio] = useState([]);

const handleChangeRepetidor = (v) => {
  props.setRepetidor(v)

  //checkMaterials()
}


/* Ja no cal, es tract al mostraQuota
const checkMaterials = () => {

  if(props.materials)
  {
   // repetidors i segon any no paguen Electude
    let noPagaElectude = props.repetidor ? true : false ;

    let mat = [...props.materials]

     mat.filter(item => item.id === "Electude").map( (i) => i.checked = noPagaElectude )

     props.setMaterials(mat)
  }

}
*/

const canviaPreuMaterial = (id,preu) => {

let mat = [...props.materials]

  mat.filter(item => item.id === id).map( (m) => m.preu = preu )

  props.setMaterials(mat)

}

const canviaTxtMaterial = (id,txt) => {

let mat = [...props.materials]

  mat.filter(item => item.id === id).map( (m) => m.txt = txt )

  props.setMaterials(mat)

}


const handleChangeMaterial = (t) => {

//console.log("MAT0",t)

let mat = [...props.materials]

//console.log("MAT1",mat)

  mat.filter(item => item.id === t).map( (i) => i.checked = !i.checked )

//console.log("MAT2",mat)

  props.setMaterials(mat)

}

const isMaterialChecked = (id) => {

let mat = props.materials.filter(item => item.id === id).pop()

if(mat === undefined ) return false
return mat.checked
}


const handleChangeBonificacions = (t) => {
  //console.log(t)
  props.setBonificacions(t)
  setBonificacio(t)
}

const handleChangeTerminis = (t) => {
  props.setDosTerminis(t)
}


// calcul quotes  / terminis
//console.log("dos", props.dosTerminis);
if (props.dosTerminis) {
  quota.termini1 =  quota.total - ( (quota.matricula - quota.bonificacio) / 2 ) 
  quota.termini2 =  (quota.matricula - quota.bonificacio) / 2
}
else {
  quota.termini1 = undefined
  quota.termini2 = undefined
}

let enguany = new Date().getFullYear()
let anyVinent = enguany + 1
anyVinent = anyVinent.toString().slice(-2)

  return (
    <div className="col col-12">

      <div className="row">
        <div className="t-Form-labelContainer">
          <label className="t-Form-label"> Quota de pagament del curs escolar {enguany}/{anyVinent}</label>
        </div>
      </div>

      <div className="row">
      <div className="col quota">


        <div className="quotaTitol">Curs: {props.curs.value} ({props.curs.id})</div>

        <div className="quotaDadesBancaries">

        { quota.total >0 && ( quota.dadesbancaries.IBAN && ( <>

          <div><span className="quotaNomCamp">Entitat bancària:</span> {quota.dadesbancaries.entitat}</div>

          <div> <span className="quotaNomCamp">nº IBAN:</span> {quota.dadesbancaries.IBAN}</div>

          </>) ) }

          <div className="quotaDadesPagamentCalendari"><span className="quotaNomCamp">Condicions:</span> {quota.dadesbancaries.condicions[props.curs.condicions]}</div>

        </div>

        <label> Conceptes </label>

        <table className="quota table">
          <tbody>



          <tr>
            <th className="quotaNomCamp" colspan="2">Assegurança escolar obligatòria i despeses bancàries: </th>
          </tr>

          <tr>
            { quota.menor28 && (<td className="col">Alumnat menor de 28 anys</td>)}
            {!quota.menor28 && (<td className="col">Alumnat de 28 anys i major</td>)}
            <td>{(quota.asseguranca).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>
          </tr>

          {props.nouAlumne !== true && (<>
            { (
               props.curs.id.startsWith("CFPM") 
                || 
               props.curs.id.startsWith("CFPS")
              ) && (
          <>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor0" name="repetidor" value="0" defaultChecked={ props.repetidor === 0 } onChange={(e) => handleChangeRepetidor(0)} ></input>
             <label for="repetidor0">Promociona a 2n</label></td>
          </tr>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor1" name="repetidor" value="1" defaultChecked={ props.repetidor === 1 } onChange={(e) => handleChangeRepetidor(1)} ></input>
             <label for="repetidor1">Repetidor 1r</label></td>
          </tr>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor2" name="repetidor" value="2" defaultChecked={ props.repetidor === 2 } onChange={(e) => handleChangeRepetidor(2)} ></input>
             <label for="repetidor2">Repetidor 2n</label></td>
          </tr>
          </>)
            }
          </>)
          }

          { quota.moduls > 0 && (
          <tr>
            <td>Material mòduls matriculats:</td>
            <td>{(quota.moduls).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
            </td>
          </tr>)
          }

          { quota.matricula > 0 && (
          <tr>
            <td>{ quota.moduls > 0 ? "Taxes mòduls matriculats:" : "Taxes:" }</td>
            <td>{(quota.matricula).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
            </td>
          </tr>)
          }



          {props.curs?.bonificacions === true && (<tr><th colspan="2" className="quotaNomCamp">Bonificacions / Exempcions:</th></tr>)}


          {props.curs?.bonificacions === true && (
                <tr>
                  <td>
                    <select id="bonificacions" name="bonificacions" defaultValue={bonificacio} onChange={(e) => handleChangeBonificacions(e.target.value)}>
                      {llista_bonificacions.map((m, k) =>
                        <option key={k} id={m.id} name={m.id} value={m.id}>{m.valor} ({m.descompte})</option>
                      )}
                    </select>
                  </td>
                  <td>
                    {quota.bonificacio >0 && "-"+(quota.bonificacio).toLocaleString(undefined, { 'maximumFractionDigits': 2 })+ "€" }
                  </td>
                </tr>
          )}

          { quota.formacio > 0 && (<tr><td className="quotaNomCamp">Despeses de formació:</td><td>{(quota.formacio).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td></tr>)}

          { /*quota.material > 0 && (<tr><td className="quotaNomCamp">Material del cicle:</td><td>{(quota.material).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td></tr>) */}

          {!props.curs.material && (<tr><td className="quotaNomCamp">Material específic del cicle:</td><td>0&euro;</td> </tr>)}
          {props.curs.material && (<tr><th colspan="2" className="quotaNomCamp">Material específic del cicle:</th></tr>)}

          {props.curs.material && props.curs.material.map((m, p) => {

            let disabled = false

            if ( props.assignatures?.length === 1 && /^FCT/.test(props.assignatures[0]) ) { // si només fan FCT no hi ha mai material
                return false
            } 
            else
            {

              let preu_material = 0;

              // MATERIALS
              switch (props.curs.id) 
              {
                // ESO 1, 2, 3 i 4
                case "ESOLOE01": 
                case "ESOLOE02": 
                case "ESOLOE03": 
                case "ESOLOE04": 
                // BATX 1 i 2
                case "BATXLOE2000": 
                case "BATXLOE20001": 
                case "BATXLOE20002": 
                case "BATXLOE3000": 
                case "BATXLOE30001": 
                case "BATXLOE30002": 
                  if( m.id === "material")
                  {
                        disabled = true; 
                        break; 
                  }

                  if( m.id === "ampa")
                  {
                        disabled = false; 
                        break; 
                  }

                // IFE
                case "IFEAR0101": 
                case "IFEAR0102": 
                case "IFEAR0103": 
                case "IFEAR0104": 

                // FP BASICA
                case "CFPBCM10": 

                  if( m.id === "material")
                  {
                        disabled = true; 
                        break; 
                  }

                  if( m.id === "ampa")
                  {
                        disabled = false; 
                        break; 
                  }


                //CFGM Perruqueria
                case "CFPMIP10": 

                //CFGM Gestió Administrativa
                case "CFPMAG10": 
                case "CFPMAG10A": 

                // CFGM Activitats Comercials
                case "CFPMCM10":
                case "CFPMCM10A":

                // CFGM d’Electromecànica de Vehicles Automòbils 
                case 'CFPMTM10':  
                case 'CFPMTM10A':  

                //CFGS Documentació i Administració Sanitària
                case "CFPSSAD0":
                case "CFPSSAD0A":

                // CFGS Laboratori d'anàlisi i control de qualitat
                case "CFPSQUD0":
                case "CFPSQUD0A":

                // CFGS Automoció
                case 'CFPSTMA0': 
                case 'CFPSTMA0A': 

                //CFGM Operacions de laboratori 
                case "CFPMQU20":
                case "CFPMQU20A":

                // CFGS Màrqueting i Publicitat
                case "CFPSCMD0":
                case "CFPSCMD0A":

                // CFGS Comerç internacional"
                case "CFPSCMB0":
                case "CFPSCMB0A":

                //CFGM de Carrosseria
                case "CFPMTM20": 
                case "CFPMTM20A": 

                // CFGM de Cures auxiliars d’infermeria
                case "CFPM1601": 
                case "CFPM1601A": 

                  const llistaAssignaturesPrimer = []
                  const llistaAssignaturesSegon = []

                  assignatures[props.curs.id].map((bloc) => {
                    bloc.subjects.map(
                      (assignatura) => {
                        if (assignatura.level === 1) { llistaAssignaturesPrimer.push(assignatura.id) }
                        if (assignatura.level === 2) { llistaAssignaturesSegon.push(assignatura.id) }
                      }
                    )
                  }
                  )

                  let matricula_parcial_1r = true;
                  if (llistaAssignaturesPrimer.length > 0) {
                    let copiaAssignatures1r = [...llistaAssignaturesPrimer]

                    matricula_parcial_1r = copiaAssignatures1r.filter((assignatura) => !props.assignatures.includes(assignatura)).length === 0 ? false : true

                  }

                  let matricula_parcial_2n = true;
                  if (llistaAssignaturesSegon.length > 0) {
                    let copiaAssignatures2n = [...llistaAssignaturesSegon]

                    matricula_parcial_2n = copiaAssignatures2n.filter((assignatura) => !props.assignatures.includes(assignatura)).length === 0 ? false : true

                  }

            if( m.id === "epi_automocio")
            {
              console.log('epi_automocio',m.preu,m.checked)

              // per mantenir el preu original en el cas que s'hagi de canviar a 0 i posteriorment restaurar
              m.preuEpiOrig = m.preuEpiOrig ?? m.preu
              m.txtEpiOrig = m.txtEpiOrig ?? m.txt

              if (!matricula_parcial_1r || !matricula_parcial_2n)  // matricula completa
              {
                if (m.preu !== 0) {
                  //m.preuEpiOrig = m.preu
                  //m.txt = m.txtEpiOrig
                  m.txt = "EPI automoció"
                  m.preu = 0
                  canviaPreuMaterial(m.id, m.preu)
                  //canviaTxtMaterial(m.id, m.txt)
                }
              }
              else  // matricula parcial
              {
                if (m.preu !== m.preuEpiOrig) {
                  m.preu = m.preuEpiOrig

                  if(props.nouAlumne) 
                  { 
                    //m.txt = m.txtEpiOrig + "xxx"
                    m.txt = "EPI automoció"
                  }
                  else
                  {
                    m.txt = "EPI automoció (opcional per repetidors)"
                  }
                  canviaPreuMaterial(m.id, m.preu)
                  //canviaTxtMaterial(m.id, m.txt)
                }
              }


              if (!matricula_parcial_1r || !matricula_parcial_2n) 
              {

                  //  si fan matricula completa no el poden triar ja que està inclòs al preu de les taxes

                  if(m.checked)
                  {
                    m.checked = false
                    let mat = [...props.materials]
                    //console.log("MAT1",mat)
                      mat.filter(item => item.id === m.id).map( (i) => i.checked = false )
                    //console.log("MAT2",mat)
                      props.setMaterials(mat)
                  }


                    disabled = true
                    break;
              }
              else
              {

                // si fan matricula parcial, pels nous és obligatori , els altres poden triar

                if(props.nouAlumne) 
                { 
                  if(!m.checked)
                  {
                    m.checked = true
                    let mat = [...props.materials]
                    //console.log("MAT1",mat)
                      mat.filter(item => item.id === m.id).map( (i) => i.checked = true )
                    //console.log("MAT2",mat)
                      props.setMaterials(mat)
                  }

              //console.log('epi_automocio 2',m.preu,m.checked)
                    disabled = true 
                    break;
                }

                
              }
              
              // nous alumnes es obligatori
              //disabled = props.nouAlumne ? true : false
              disabled = false

              break; 
            }

            if(m.id=== "pijama_cai")
            {

                //console.log("PIJAMA 0",m.checked,props.materials);

              // per mantenir el preu original en el cas que s'hagi de canviar a 0 i posteriorment restaurar
              m.preuPijamaOrig = m.preuPijamaOrig ?? m.preu
              m.txtPijamaOrig = m.txtPijamaOrig ?? m.txt

              if (!matricula_parcial_1r || !matricula_parcial_2n) 
              {

                  //  si fan matricula completa l'han de demanar 

                  if(!m.checked)
                  {
                    m.checked = true
                    let mat = [...props.materials]
                    mat.filter(item => item.id === m.id).map((i) => i.checked = true)
                    props.setMaterials(mat)
                  }

                   m.txt = m.txtPijamaOrig

                    disabled = true
                    break;
              }
              else
              {

                // si fan matricula parcial, pels nous és obligatori , els altres poden triar

                if(props.nouAlumne) 
                { 

                  if(!m.checked)
                  {
                    let mat = [...props.materials]
                      mat.filter(item => item.id === m.id).map( (i) => i.checked = true )
                      props.setMaterials(mat)
                    }
                    /*
                  */

                   m.txt = m.txtPijamaOrig

                    disabled = true 
                    break;
                }

                
              }

                  m.txt = m.txtPijamaOrig + " (opcional per repetidors)"
              
              disabled = false
              break; 
            }

            // Material curs
            if(m.id=== "material")
            {
              // per mantenir el preu original en el cas que s'hagi de canviar a 0 i posteriorment restaurar
              m.preuOrig = m.preuOrig ?? m.preu
              m.txtOrig = m.txtOrig ?? m.txt

              if (!matricula_parcial_1r || !matricula_parcial_2n) 
              {
                if (m.preu !== m.preuOrig) {
                  m.preu = m.preuOrig
                  m.txt = m.txtOrig
                  canviaPreuMaterial(m.id, m.preu)
                  canviaTxtMaterial(m.id, m.txt)
                }
              }
              else 
              {
                if (m.preu !== 0) {
                  m.preuOrig = m.preu
                  m.txtOrig= m.txt
                  m.preu = 0
                  m.txt = "Material curs"
                  canviaPreuMaterial(m.id, m.preu)
                  canviaTxtMaterial(m.id, m.txt)
                }
              }

              // sempre obligatori - només canvia preu si no és necessari pel curs
              disabled = true;
              break;
            }

            if( m.id === "ampa")
            {
                  disabled = false; 
                  break; 
            }

            default:


                    if( m.id === "ampa")
                    {
                          disabled = false; 
                          break; 
                    }
                    else
                    {
                      disabled = true; 
                    }

                    break; 

              }
            }

          return (
            <>
          <tr>
            <td>
            <input type="checkbox" id={m.id} name={m.id} value={m.preu} checked={isMaterialChecked(m.id)} disabled={disabled} onChange={(e) => handleChangeMaterial(e.target.id)} />
            <label for={m.id}>{m.txt}</label>
            </td>
            <td>
            {m.preu}&euro;
          </td></tr>

              {/* isMaterialChecked(m.id) && m.info !== undefined ?*/}
              { m.info !== undefined ?
                    (<tr>
                      <td>
                        <div style={{ maxWidth: '75%', border: '2px solid #c00000', margin: '5px 0px', padding: 5 }} dangerouslySetInnerHTML={{ __html: m.info }} />
                      </td>
                      <td>
                      </td>
                    </tr>)
                    : null
                }

          </>
          )}
          )}

          <tr><th className="quotaNomCamp" colSpan="2"> Total a abonar a compte:</th></tr>

          <tr>
          { quota.menor28 ? (<td>Alumnat menor de 28 anys</td>) : (<td>Alumnat de 28 anys i major:</td>)}

           <td className="quotaTotal"> {(quota.total).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>
          </tr>

          { props.curs.condicions === 1 && quota.bonificacio < quota.matricula ? 
          (<tr>
            <td colspan="2">
            <input type="checkbox" id="dosTerminis" name="dosTerminis" value={true} checked={props.dosTerminis} onChange={(e) => props.setDosTerminis(e.target.checked)} />
            <label for="dosTerminis">Vull fer el pagament en 2 terminis</label>
            </td>
            <td>
          </td></tr>)
          : (<>{props.setDosTerminis(false)}</>)

          }


           { props.dosTerminis && (
              <React.Fragment>
              <tr>
                <td>
                  Pagament 1r termini:
                </td>
                <td>
                  { quota.termini1 && (quota.termini1).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
                </td>
              </tr>
              <tr>
                <td>
                  Pagament 2n termini:
                </td>
                <td>
                  { quota.termini2 && (quota.termini2).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
                </td>
              </tr>
              </React.Fragment>
            )}

          </tbody>
        </table>

      </div>
      </div>

    </div >
  )
  
 }
